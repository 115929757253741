
.font{
   color: #03d999;
}

//font imports

@import url('https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300,700|Rozha+One&display=swap');

@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap');

@font-face {
    font-family: road_rage;
    src: url('/fonts/Road_Rage.otf')
}


// font families


$primary-font: 'Roboto', sans-serif;

$secondary-font: 'Lato', sans-serif;

$tertiary-font: 'Alegreya Sans', sans-serif;

$textFont:  'Roboto Condensed', sans-serif;

$headerFont: 'Roboto', sans-serif;

$displayFont: 'Open Sans Condensed', sans-serif;

$regulaSans: 'Open Sans', sans-serif;

$rozhaFont: 'Rozha One', serif;


// colors
$primary-color: linear-gradient(165deg, rgba(37,20,49,1) 0%, rgba(22,17,48,1) 22%);

$secondary-color: #EB4E7A;

$tertiary-color: #1C262B;

$primary-font-color: #fff;

$secondary-font-color: rgba(255, 255, 255, .9);

$titleColor: #AAAAAA;

$paragraphColor: #ADADAD;

$footerBG: #131A22;

$black: #202020;

$red: #911A20;

$green: #4D6010;

$greyBG: #F7F7F7;

$main-color: #ffbc00;

$light-color: #f4f4f4;

$medium-color: #ccc;

$dark-color: #333;

$bg-image: url('../img/showcase.jpg');

// Media Queries

@function set-text-color($color) {
  @if (lightness($color) > 50) {
    @return #000;
  } @else {
    @return #fff;
  }
}

$website-width: 950px;

// Tablets & Smaller
@media (max-width: 800px) {
    #header-home {
      height: 30rem;
  
      .header-content {
        padding-top: 5rem;
      }
    }
  }
  
  // Smartphones
  @media (max-width: 500px) {
    #main-nav {
      flex-direction: column;
      align-items: center;
  
      li {
        padding: 1rem;
      }
    }
  
    #header {
      &-home {
        height: 10rem;
        border-bottom: 3px solid $main-color;
        background-position: 20% 30%;
  
        .header-content {
          display: none;
        }
      }
  
      &-inner {
        height: 10rem;
      }
    }
  
    // Stack Grid Columns
    #home-a .specials,
    #home-b .stats,
    #home-c .process,
    #about-d .testimonials,
    #contact-b .contact-info,
    .items {
      grid-template-columns: 1fr;
    }
  
    // Home
    #home {
      &-a {
        .specials div {
          border-bottom: 1px $light-color solid;
          padding-bottom: 1rem;
  
          &:last-child {
            border: none;
            padding-bottom: 0;
          }
        }
      }
  
      &-b {
        .stats div {
          padding: 2rem 0 1rem 0;
        }
      }
    }
  
    // About
    #about {
      &-a {
        .about-info {
          grid-template-areas:
            'bioimage'
            'bio'
            'aw1'
            'aw2'
            'aw3';
        }
      }
  
      &-c {
        display: none;
      }
    }
  
    // Contact
    #contact {
      &-a {
        .text-fields {
          grid-template-areas:
            'name'
            'subject'
            'email'
            'phone'
            'message';
        }
      }
  
      &-b {
        div {
          margin-bottom: 1rem;
          padding-bottom: 1rem;
          border-bottom: 1px #555 solid;
  
          &:last-child {
            border: none;
            margin-bottom: 0;
            padding-bottom: 0;
          }
        }
      }
  
      &-c {
        h1 {
          font-size: 2rem;
        }
      }
    }
  
    // Footer
    #main-footer {
      height: 7rem;
  
      .footer-content {
        flex-direction: column;
        padding: 1rem;
        height: 5rem;
      }
    }
  }
  
  // Landscape
  @media (max-height: 580px) {
    #header-home {
      .header-content {
        padding-top: 3rem;
      }
    }
  }
  
  @media (max-height: 330px) {
    #header-home {
      .header-content {
        h1 {
          font-size: 2rem;
        }
      }
    }
  }
  

