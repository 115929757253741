@import './variables.scss';

///TOP MENU 
.wrapper-container-menu{
        @media only screen and (max-width: 1900px) {
            display: flex;
            justify-content: space-between;
            max-width: 1100px;
            padding: 0 1.5rem;
            margin: auto;
            padding-top: 6rem;
            padding-bottom: 2rem;
            overflow: hidden;
            height: 57px;
        }
      @media only screen and (min-width: 1900px) {
        display: flex;
        justify-content: space-between;
        max-width: 1100px;
        padding: 0 1.5rem;
        margin: auto;
        padding-top: 6rem;
        padding-bottom: 2rem;
        overflow: hidden;
        height: 57px;
        font-size: 1.3rem;
      }

    .logo{
        display: flex;
    }
    .wrapper-title { 
      letter-spacing: 2px;
      font-size: 2rem;
      font-weight: 900;
      font-family: $primary-font;
      position: fixed;
      top: 2.4rem;
      @media only screen and (max-width: 320px) {
        top: 28px;
      }
    }
    .wrapper-subtitle-{
        color: #e0bd44;
        align-self: flex-end;
    }
    .wrapper-menu{
        @media only screen and (max-width: 700px) {
            display: none;
        } 
        @media only screen and (min-width: 700px) {
            display: flex;
            align-self: flex-end;
            padding-bottom: 2.5rem;
        } 
      .wrapper-menu-item {
        padding-left: 20px;
        letter-spacing: 2px;
        font-family: $primary-font;
        &:hover{
            cursor: pointer;
            color: #E3545f;
        }
      }
    }
  } 



 
////RIGHT SIDEWAYS NAVIGATION
 
.sideways-right-nav-wrapper{
    transform: rotate(-90deg);
    height: 2rem;
    position: fixed;
    right: -16rem;
    width: 35rem;
    color: #000;
    top: 31.2rem;
    justify-content: center;
    align-items: center;
    display: none;

    @media only screen and (min-width: 1900px) {
        right: -15rem;
    }
    @media screen and (max-width: 1400px) {
        top: 25.2rem;
    }
    @media screen and (max-width: 1100px) {
        display: none;
    }
    .rotate{
        transform: rotate(-90deg); 
    }
    .navigation-right-wrapper{
        list-style-type: none;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        @media only screen and (min-width: 1900px) {
            width: 40rem;
        }
        .navigation-link-right {
            color: #000;
            .navigation-list-right {
                font-weight: 1000;
                letter-spacing: 2px;
                font-size: 1.3rem;
                color: #000;
                font-family: $headerFont;
                color: #000;
                @media only screen and (min-width: 1900px) {
                    font-size: 1.8rem;
                }
            }
        } 
    }
}


/////////////////LEFT SIDEWAYS NAVIGATION////////////////////

  .sideways-left-nav-wrapper{
    transform: rotate(-90deg);
    height: 2rem;
    position: fixed;
    left: -9rem;
    color: #000;
    top: 28.2rem;
    justify-content: center;
    align-items: center;
    display: none;
    @media screen and (max-width: 1400px) {
        top: 22.2rem;
    }
    @media screen and (max-width: 1100px) {
        display: none;
    }
    .navigation-left-wrapper{
        list-style-type: none;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 24rem;
        cursor: default;
        .navigation-link-left {
            color: #000;
            cursor: default;
            .navigation-list-left {
                font-weight: 1000;
                letter-spacing: 2px;
                font-size: 1.3rem;
                color: #000;
                font-family: $headerFont;
                color: #000;
                cursor: default;
                @media only screen and (min-width: 1900px) {
                    font-size: 1.8rem;
                }
            }
        }
    }
}

////////MEDIA QUERIES///////////////////////
.Wrapper {
    button {
            @media only screen and (max-width: 700px) {
                display: flex;
                align-self: center;
            } 
            @media only screen and (min-width: 700px) {
                display: none;
            } 
        }
}

#arrow__nav .arrow {
    font-size: 1.4rem;
}

/////////////////////////ANIMATIONS///////////////////////////////////////////////////

.m-fadeOut {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 300ms, opacity 300ms;
}



