@import './variables.scss';

.ProjectViewTop{
    max-width: 1000px; 
    padding: 0 1.5rem;
    margin: auto;
    overflow: hidden;
    padding-top: 7rem;
    font-family: $primary-font;
    @media only screen and (max-width: 425px) {
        padding: 0;
    }
    @media only screen and (max-width: 768px) {
        padding-top: 0rem;
    }
    .top-container{
        width: 100%;
        height: 40vh;
        @media only screen and (max-width: 768px) {
            height: 41vh;
        }
    }
    .description{
        margin-left: 56px;
        padding-top: 25px;
        @media only screen and (max-width: 425px) {
            margin-left: 0;
            padding-top: 1.3rem;
        }
        .paragraph{
            margin-top: 3%;
            line-height: 1.5rem;
            font-size: .9rem;
            font-family: $primary-font;
            width: 31rem;
            @media only screen and (max-width: 425px) {
                width: 15rem;
            }
        }
        .title-one {
            font-size: 3rem;
        }
        .secondary-line{
            border: .5px solid black;
            display: flex;
            width: 30px;
        }
    }
}


