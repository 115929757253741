@import url(https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300,700|Rozha+One&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300,700|Rozha+One&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300,700|Rozha+One&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300,700|Rozha+One&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300,700|Rozha+One&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300,700|Rozha+One&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300,700|Rozha+One&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300,700|Rozha+One&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap);
.font {
  color: #03d999; }

@font-face {
  font-family: road_rage;
  src: url("/fonts/Road_Rage.otf"); }

@media (max-width: 800px) {
  #header-home {
    height: 30rem; }
    #header-home .header-content {
      padding-top: 5rem; } }

@media (max-width: 500px) {
  #main-nav {
    flex-direction: column;
    align-items: center; }
    #main-nav li {
      padding: 1rem; }
  #header-home {
    height: 10rem;
    border-bottom: 3px solid #ffbc00;
    background-position: 20% 30%; }
    #header-home .header-content {
      display: none; }
  #header-inner {
    height: 10rem; }
  #home-a .specials,
  #home-b .stats,
  #home-c .process,
  #about-d .testimonials,
  #contact-b .contact-info,
  .items {
    grid-template-columns: 1fr; }
  #home-a .specials div {
    border-bottom: 1px #f4f4f4 solid;
    padding-bottom: 1rem; }
    #home-a .specials div:last-child {
      border: none;
      padding-bottom: 0; }
  #home-b .stats div {
    padding: 2rem 0 1rem 0; }
  #about-a .about-info {
    grid-template-areas: 'bioimage' 'bio' 'aw1' 'aw2' 'aw3'; }
  #about-c {
    display: none; }
  #contact-a .text-fields {
    grid-template-areas: 'name' 'subject' 'email' 'phone' 'message'; }
  #contact-b div {
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px #555 solid; }
    #contact-b div:last-child {
      border: none;
      margin-bottom: 0;
      padding-bottom: 0; }
  #contact-c h1 {
    font-size: 2rem; }
  #main-footer {
    height: 7rem; }
    #main-footer .footer-content {
      flex-direction: column;
      padding: 1rem;
      height: 5rem; } }

@media (max-height: 580px) {
  #header-home .header-content {
    padding-top: 3rem; } }

@media (max-height: 330px) {
  #header-home .header-content h1 {
    font-size: 2rem; } }

.font {
  color: #03d999; }

@font-face {
  font-family: road_rage;
  src: url("/fonts/Road_Rage.otf"); }

@media (max-width: 800px) {
  #header-home {
    height: 30rem; }
    #header-home .header-content {
      padding-top: 5rem; } }

@media (max-width: 500px) {
  #main-nav {
    flex-direction: column;
    align-items: center; }
    #main-nav li {
      padding: 1rem; }
  #header-home {
    height: 10rem;
    border-bottom: 3px solid #ffbc00;
    background-position: 20% 30%; }
    #header-home .header-content {
      display: none; }
  #header-inner {
    height: 10rem; }
  #home-a .specials,
  #home-b .stats,
  #home-c .process,
  #about-d .testimonials,
  #contact-b .contact-info,
  .items {
    grid-template-columns: 1fr; }
  #home-a .specials div {
    border-bottom: 1px #f4f4f4 solid;
    padding-bottom: 1rem; }
    #home-a .specials div:last-child {
      border: none;
      padding-bottom: 0; }
  #home-b .stats div {
    padding: 2rem 0 1rem 0; }
  #about-a .about-info {
    grid-template-areas: 'bioimage' 'bio' 'aw1' 'aw2' 'aw3'; }
  #about-c {
    display: none; }
  #contact-a .text-fields {
    grid-template-areas: 'name' 'subject' 'email' 'phone' 'message'; }
  #contact-b div {
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px #555 solid; }
    #contact-b div:last-child {
      border: none;
      margin-bottom: 0;
      padding-bottom: 0; }
  #contact-c h1 {
    font-size: 2rem; }
  #main-footer {
    height: 7rem; }
    #main-footer .footer-content {
      flex-direction: column;
      padding: 1rem;
      height: 5rem; } }

@media (max-height: 580px) {
  #header-home .header-content {
    padding-top: 3rem; } }

@media (max-height: 330px) {
  #header-home .header-content h1 {
    font-size: 2rem; } }

html, body {
  width: 100vw !important; }

body {
  overflow-x: hidden !important; }

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1;
  color: #202020;
  background: white; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after {
  content: '';
  content: none; }

q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit; }

a {
  -webkit-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
  cursor: pointer; }
  a:hover {
    color: #911A20; }

.cursor {
  width: 40px;
  height: 40px;
  border: 1.5px solid #ef3f4a;
  border-radius: 50%;
  position: absolute;
  -webkit-transition-duration: 200ms;
          transition-duration: 200ms;
  -webkit-transition-timing-function: ease-out;
          transition-timing-function: ease-out;
  -webkit-animation: cursorAnim .5s infinite alternate;
          animation: cursorAnim .5s infinite alternate;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-items: center;
  align-content: center;
  justify-content: center; }

.cursor--small {
  width: 5px;
  height: 5px;
  left: -2.5px;
  top: -2.5px;
  border-radius: 50%;
  z-index: 15551000;
  background: #ef3f4a; }

@-webkit-keyframes cursorAnim {
  from {
    -webkit-transform: scale(1);
            transform: scale(1); }
  to {
    -webkit-transform: scale(0.7);
            transform: scale(0.7); } }

@keyframes cursorAnim {
  from {
    -webkit-transform: scale(1);
            transform: scale(1); }
  to {
    -webkit-transform: scale(0.7);
            transform: scale(0.7); } }

@-webkit-keyframes cursorAnim2 {
  from {
    -webkit-transform: scale(1);
            transform: scale(1); }
  to {
    -webkit-transform: scale(0.4);
            transform: scale(0.4); } }

@keyframes cursorAnim2 {
  from {
    -webkit-transform: scale(1);
            transform: scale(1); }
  to {
    -webkit-transform: scale(0.4);
            transform: scale(0.4); } }

@-webkit-keyframes cursorAnim3 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  50% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0; } }

@keyframes cursorAnim3 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  50% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0; } }

.expand {
  -webkit-animation: cursorAnim3 .5s forwards;
          animation: cursorAnim3 .5s forwards;
  border: .2px solid red; }
  @media only screen and (max-width: 800px) {
    .expand {
      -webkit-animation: none;
              animation: none; } }

.LandingPage {
  top: 0;
  left: 0;
  margin: 0;
  width: 100%;
  height: 100vh;
  background-color: #000;
  color: #fff;
  position: absolute; }

.sideways-nav-landing {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  height: 2rem;
  position: fixed;
  right: -11rem;
  background: #000;
  color: white;
  top: 60vh;
  justify-content: center;
  align-items: center; }
  @media only screen and (max-width: 425px) {
    .sideways-nav-landing {
      display: none; } }
  @media only screen and (min-width: 769px) {
    .sideways-nav-landing {
      top: 60vh; } }
  @media only screen and (min-width: 1440px) {
    .sideways-nav-landing {
      top: 60vh;
      right: -15rem; } }
  .sideways-nav-landing .navigation-landing {
    list-style-type: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 24rem; }
    @media only screen and (min-width: 1440px) {
      .sideways-nav-landing .navigation-landing {
        width: 33rem; } }
    .sideways-nav-landing .navigation-landing .navigation-link {
      color: white; }
      .sideways-nav-landing .navigation-landing .navigation-link li {
        font-weight: 1000;
        letter-spacing: 2px;
        font-size: 1rem;
        color: white;
        font-family: "Roboto", sans-serif;
        color: white; }
        @media only screen and (min-width: 1440px) {
          .sideways-nav-landing .navigation-landing .navigation-link li {
            font-size: 1.3rem; } }
        .sideways-nav-landing .navigation-landing .navigation-link li:hover {
          cursor: pointer;
          color: #46e0b4; }

.LandingPage-title {
  padding-top: 266px;
  padding-left: 11%;
  color: white; }
  @media only screen and (max-width: 425px) {
    .LandingPage-title {
      margin: 0 auto;
      display: block;
      padding-left: 0rem; } }
  .LandingPage-title .presentation-name {
    font-size: 1.8rem;
    line-height: 1.21429em;
    letter-spacing: -.03571em;
    font-family: Helvetica,Arial,sans-serif;
    font-weight: 700;
    margin: 0;
    word-break: break-word;
    font-weight: 900;
    padding-bottom: 6px;
    color: white; }
    @media only screen and (max-width: 425px) {
      .LandingPage-title .presentation-name {
        padding-bottom: 0;
        font-size: 1rem; } }
  .LandingPage-title .presentation-landing-page {
    max-width: 31.27273rem;
    height: 4.63636rem;
    font-size: 5rem;
    font-weight: 800;
    color: white;
    padding-right: 13px; }
    @media only screen and (max-width: 425px) {
      .LandingPage-title .presentation-landing-page {
        padding-right: 0;
        font-size: 3rem;
        height: 3.63636rem; } }

.landing-page-nav {
  color: white; }

.scroll-down {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 89%;
  left: 11%;
  color: white; }
  @media only screen and (max-width: 425px) {
    .scroll-down {
      padding-left: 0rem; } }
  .scroll-down .alternate {
    align-self: flex-end; }
  .scroll-down .scroll-title {
    margin-bottom: 0;
    margin-top: 0; }
    @media only screen and (min-width: 1025px) {
      .scroll-down .scroll-title {
        font-size: 1.2rem; } }

.typewriter {
  display: inline-block; }
  .typewriter .typewriter-text {
    display: inline-block;
    overflow: hidden;
    letter-spacing: 2px;
    -webkit-animation: typing 2s steps(30, end), blink 1s step-end infinite;
            animation: typing 2s steps(30, end), blink 1s step-end infinite;
    white-space: nowrap;
    font-weight: 700;
    border-right: 4px solid #fff;
    box-sizing: border-box; }
    @media only screen and (min-width: 1920px) {
      .typewriter .typewriter-text {
        overflow: visible;
        -webkit-animation: none;
                animation: none; } }

.description-landing {
  width: 600px;
  font-family: "Roboto", sans-serif;
  color: white;
  font-size: 2rem;
  padding-top: 13px; }
  @media only screen and (max-width: 425px) {
    .description-landing {
      font-size: 1.5rem;
      padding-top: 0; } }
  .description-landing .words {
    display: inline;
    text-indent: 20px; }
    @media only screen and (max-width: 425px) {
      .description-landing .words span {
        width: 170px; } }

.words span {
  position: absolute;
  opacity: 0;
  overflow: hidden;
  color: #ef3f4a;
  -webkit-animation: rotateWord 72s ease-in-out infinite 0s;
  animation: rotateWord 72s ease-in-out infinite 0s; }

.words span:nth-child(2) {
  -webkit-animation-delay: 3s;
  animation-delay: 3s; }

.words span:nth-child(3) {
  -webkit-animation-delay: 6s;
  animation-delay: 6s; }

.words span:nth-child(4) {
  -webkit-animation-delay: 9s;
  animation-delay: 9s; }

.words span:nth-child(5) {
  -webkit-animation-delay: 12s;
  animation-delay: 12s; }

.words span:nth-child(6) {
  -webkit-animation-delay: 15s;
  animation-delay: 15s; }

.words span:nth-child(7) {
  -webkit-animation-delay: 18s;
  animation-delay: 18s; }

.words span:nth-child(8) {
  -webkit-animation-delay: 21s;
  animation-delay: 21s; }

.words span:nth-child(9) {
  -webkit-animation-delay: 24s;
  animation-delay: 24s; }

.words span:nth-child(10) {
  -webkit-animation-delay: 27s;
  animation-delay: 27s; }

.words span:nth-child(11) {
  -webkit-animation-delay: 30s;
  animation-delay: 30s; }

.words span:nth-child(12) {
  -webkit-animation-delay: 33s;
  animation-delay: 33s; }

.words span:nth-child(13) {
  -webkit-animation-delay: 36s;
  animation-delay: 36s; }

.words span:nth-child(14) {
  -webkit-animation-delay: 39s;
  animation-delay: 39s; }

.words span:nth-child(15) {
  -webkit-animation-delay: 42s;
  animation-delay: 42s; }

.words span:nth-child(16) {
  -webkit-animation-delay: 45s;
  animation-delay: 45s; }

.words span:nth-child(17) {
  -webkit-animation-delay: 48s;
  animation-delay: 48s; }

.words span:nth-child(18) {
  -webkit-animation-delay: 51s;
  animation-delay: 51s; }

.words span:nth-child(19) {
  -webkit-animation-delay: 54s;
  animation-delay: 54s; }

.words span:nth-child(20) {
  -webkit-animation-delay: 57s;
  animation-delay: 57s; }

.words span:nth-child(21) {
  -webkit-animation-delay: 60s;
  animation-delay: 60s; }

.words span:nth-child(22) {
  -webkit-animation-delay: 63s;
  animation-delay: 63s; }

.words span:nth-child(23) {
  -webkit-animation-delay: 66s;
  animation-delay: 66s; }

.words span:nth-child(24) {
  -webkit-animation-delay: 69s;
  animation-delay: 69s; }

@-webkit-keyframes rotateWord {
  0% {
    opacity: 0; }
  1% {
    opacity: 0;
    -webkit-transform: translateY(-30px); }
  2% {
    opacity: 1;
    -webkit-transform: translateY(0px); }
  4% {
    opacity: 1;
    -webkit-transform: translateY(0px); }
  5% {
    opacity: 0;
    -webkit-transform: translateY(30px); }
  80% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@keyframes rotateWord {
  0% {
    opacity: 0; }
  1% {
    opacity: 0;
    -webkit-transform: translateY(-30px); }
  2% {
    opacity: 1;
    -webkit-transform: translateY(0px); }
  4% {
    opacity: 1;
    -webkit-transform: translateY(0px); }
  5% {
    opacity: 0;
    -webkit-transform: translateY(30px); }
  80% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@-webkit-keyframes typing {
  from {
    width: 0%; }
  to {
    width: 100%; } }

@keyframes typing {
  from {
    width: 0%; }
  to {
    width: 100%; } }

@-webkit-keyframes blink {
  from, to {
    border-color: transparent; }
  50% {
    border-color: orange; } }

@keyframes blink {
  from, to {
    border-color: transparent; }
  50% {
    border-color: orange; } }

.animated .down {
  -webkit-animation-duration: 1.3s;
          animation-duration: 1.3s;
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite; }
  @media only screen and (min-width: 1025px) {
    .animated .down {
      font-size: 1.2rem; } }

@media only screen and (max-width: 425px) {
  .presentation-name {
    width: 250px; }
  .typewriter {
    width: 250px; }
  .description-landing {
    width: 250px; }
  #landing-page-section {
    display: flex; }
  .words span {
    width: 50px; } }

@media only screen and (min-width: 1920px) {
  .down .icon {
    font-size: 3rem; } }

.loader-container {
  background: -webkit-linear-gradient(315deg, #eb4e7a 0%, #1c262b 71%);
  background: linear-gradient(135deg, #eb4e7a 0%, #1c262b 71%);
  height: 100vh;
  opacity: 1;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 1000; }

.loader {
  position: absolute;
  top: calc(50% - 32px);
  left: calc(50% - 32px);
  width: 64px;
  height: 64px;
  border-radius: 50%;
  -webkit-perspective: 800px;
          perspective: 800px;
  z-index: 1000; }

.inner {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  z-index: 1000; }

.inner.one {
  left: 0%;
  top: 0%;
  -webkit-animation: rotate-one 1s linear infinite;
          animation: rotate-one 1s linear infinite;
  border-bottom: 3px solid #EFEFFA;
  z-index: 1000; }

.inner.two {
  right: 0%;
  top: 0%;
  -webkit-animation: rotate-two 1s linear infinite;
          animation: rotate-two 1s linear infinite;
  border-right: 3px solid #EFEFFA;
  z-index: 1000; }

.inner.three {
  right: 0%;
  bottom: 0%;
  -webkit-animation: rotate-three 1s linear infinite;
          animation: rotate-three 1s linear infinite;
  border-top: 3px solid #EFEFFA;
  z-index: 1000; }

@-webkit-keyframes rotate-one {
  0% {
    -webkit-transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
            transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg); }
  100% {
    -webkit-transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
            transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg); } }

@keyframes rotate-one {
  0% {
    -webkit-transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
            transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg); }
  100% {
    -webkit-transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
            transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg); } }

@-webkit-keyframes rotate-two {
  0% {
    -webkit-transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
            transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg); }
  100% {
    -webkit-transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
            transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg); } }

@keyframes rotate-two {
  0% {
    -webkit-transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
            transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg); }
  100% {
    -webkit-transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
            transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg); } }

@-webkit-keyframes rotate-three {
  0% {
    -webkit-transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
            transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg); }
  100% {
    -webkit-transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
            transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg); } }

@keyframes rotate-three {
  0% {
    -webkit-transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
            transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg); }
  100% {
    -webkit-transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
            transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg); } }

.font {
  color: #03d999; }

@font-face {
  font-family: road_rage;
  src: url("/fonts/Road_Rage.otf"); }

@media (max-width: 800px) {
  #header-home {
    height: 30rem; }
    #header-home .header-content {
      padding-top: 5rem; } }

@media (max-width: 500px) {
  #main-nav {
    flex-direction: column;
    align-items: center; }
    #main-nav li {
      padding: 1rem; }
  #header-home {
    height: 10rem;
    border-bottom: 3px solid #ffbc00;
    background-position: 20% 30%; }
    #header-home .header-content {
      display: none; }
  #header-inner {
    height: 10rem; }
  #home-a .specials,
  #home-b .stats,
  #home-c .process,
  #about-d .testimonials,
  #contact-b .contact-info,
  .items {
    grid-template-columns: 1fr; }
  #home-a .specials div {
    border-bottom: 1px #f4f4f4 solid;
    padding-bottom: 1rem; }
    #home-a .specials div:last-child {
      border: none;
      padding-bottom: 0; }
  #home-b .stats div {
    padding: 2rem 0 1rem 0; }
  #about-a .about-info {
    grid-template-areas: 'bioimage' 'bio' 'aw1' 'aw2' 'aw3'; }
  #about-c {
    display: none; }
  #contact-a .text-fields {
    grid-template-areas: 'name' 'subject' 'email' 'phone' 'message'; }
  #contact-b div {
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px #555 solid; }
    #contact-b div:last-child {
      border: none;
      margin-bottom: 0;
      padding-bottom: 0; }
  #contact-c h1 {
    font-size: 2rem; }
  #main-footer {
    height: 7rem; }
    #main-footer .footer-content {
      flex-direction: column;
      padding: 1rem;
      height: 5rem; } }

@media (max-height: 580px) {
  #header-home .header-content {
    padding-top: 3rem; } }

@media (max-height: 330px) {
  #header-home .header-content h1 {
    font-size: 2rem; } }

.profile-container {
  position: relative; }
  .profile-container .main-profile-container {
    display: flex; }
  .profile-container .profile-container-image {
    padding-top: 6rem;
    border-radius: 2rem; }
    .profile-container .profile-container-image .img {
      border-radius: 2rem; }
  .profile-container .discover {
    position: absolute;
    color: #202020;
    left: 84%; }
    .profile-container .discover:hover {
      color: #46e0b4;
      cursor: pointer;
      -webkit-transform: scale(1.02);
              transform: scale(1.02); }
      .profile-container .discover:hover ::after {
        content: '';
        top: 0;
        left: 0;
        width: 0%;
        height: 100%;
        background: #e06246;
        position: absolute;
        -webkit-animation: reveal-animation 1.5s cubic-bezier(0.19, 1, 0.22, 1) forwards;
                animation: reveal-animation 1.5s cubic-bezier(0.19, 1, 0.22, 1) forwards;
        -webkit-animation-delay: 1.6s;
                animation-delay: 1.6s; }
    .profile-container .discover .profile-container-discover {
      opacity: 0;
      -webkit-animation: reveal-text .0001s linear forwards;
              animation: reveal-text .0001s linear forwards;
      -webkit-animation-delay: 2.2s;
              animation-delay: 2.2s; }
    .profile-container .discover .year {
      position: absolute;
      color: #202020;
      right: 80%;
      top: 80%; }
  .profile-container .title {
    padding-top: 6rem;
    font-size: 72px;
    font-family: "charm";
    line-height: 1;
    color: #EBD6A3;
    z-index: 100;
    top: 12%;
    left: 79%;
    position: absolute;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
    .profile-container .title ::after {
      content: '';
      top: 0;
      left: 0;
      width: 0%;
      height: 100%;
      background: #EBD6A3;
      position: absolute;
      -webkit-animation: reveal-animation 1.5s cubic-bezier(0.19, 1, 0.22, 1) forwards;
              animation: reveal-animation 1.5s cubic-bezier(0.19, 1, 0.22, 1) forwards;
      -webkit-animation-delay: 1.4s;
              animation-delay: 1.4s; }
    .profile-container .title .hide-text {
      opacity: 0;
      -webkit-animation: reveal-text .0001s linear forwards;
              animation: reveal-text .0001s linear forwards;
      -webkit-animation-delay: 2s;
              animation-delay: 2s; }

.sideways-nav {
  display: none;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  height: 2rem;
  position: fixed;
  right: -132px;
  top: 0px;
  background: #000;
  color: white; }
  .sideways-nav .navigation {
    list-style-type: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 400px;
    color: white; }

.number {
  display: flex;
  align-items: center;
  flex-direction: row;
  align-content: space-between; }
  .number .line {
    border: .5px solid black;
    width: 40px;
    justify-self: center; }
  .number .line-number-four {
    margin-top: 0px;
    padding-left: 15px; }

.network-bottom {
  max-width: 1100px;
  padding: 0 1.5rem;
  margin: auto;
  overflow: hidden; }
  @media only screen and (max-width: 767px) {
    .network-bottom {
      display: none; } }
  .network-bottom .static-nav {
    display: flex;
    align-items: center;
    margin-top: 0px;
    padding-top: 55px;
    padding-bottom: 60px;
    max-width: 100px; }
    .network-bottom .static-nav .social-media-icons {
      display: flex;
      padding-left: 43px; }
    .network-bottom .static-nav .page-four {
      padding-right: 35px; }
  .network-bottom #intrinsic-img {
    width: 188px;
    height: 134px; }
    @media only screen and (min-width: 1440px) {
      .network-bottom #intrinsic-img {
        width: 199px;
        height: 134px; } }

.network-boxes-top {
  display: flex; }
  .network-boxes-top .network-boxes-top-row-one {
    width: 205px;
    height: 205px; }
  .network-boxes-top .network-boxes-top-row-two {
    width: 205px;
    height: 205px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-right: 132px;
    padding-top: 75px; }
  .network-boxes-top .network-boxes-top-row-three {
    width: 205px;
    height: 205px; }
  .network-boxes-top .network-boxes-top-row-four {
    width: 205px;
    height: 205px;
    background-color: #aef0d2; }
  .network-boxes-top .network-boxes-top-row-five {
    width: 205px;
    height: 205px; }

.network-boxes-middle {
  display: flex; }
  @media only screen and (max-width: 768px) {
    .network-boxes-middle {
      width: 90%;
      margin: 0 auto; } }
  .network-boxes-middle .network-boxes-middle-row-one {
    width: 205px;
    height: 205px; }
  .network-boxes-middle .network-boxes-middle-row-two {
    width: 205px;
    height: 205px; }
    @media only screen and (max-width: 768px) {
      .network-boxes-middle .network-boxes-middle-row-two {
        width: 211px; } }
  .network-boxes-middle .network-boxes-middle-row-three {
    width: 205px;
    height: 205px;
    background-color: #f9cbd1; }
    @media only screen and (max-width: 768px) {
      .network-boxes-middle .network-boxes-middle-row-three {
        display: none; } }
  .network-boxes-middle .network-boxes-middle-row-four {
    width: 205px;
    height: 205px; }
  .network-boxes-middle .network-boxes-middle-row-five {
    width: 205px;
    height: 205px; }

.network-boxes-bottom {
  display: flex; }
  @media only screen and (max-width: 768px) {
    .network-boxes-bottom {
      width: 90%;
      margin: 0 auto; } }
  .network-boxes-bottom .network-boxes-bottom-row-one {
    width: 205px;
    height: 205px;
    background-color: #f9cbd1; }
  .network-boxes-bottom .network-boxes-bottom-row-two {
    width: 205px;
    height: 205px; }
    @media only screen and (max-width: 768px) {
      .network-boxes-bottom .network-boxes-bottom-row-two {
        display: none; } }
  .network-boxes-bottom .network-boxes-bottom-row-three {
    width: 205px;
    height: 205px; }
    @media only screen and (max-width: 768px) {
      .network-boxes-bottom .network-boxes-bottom-row-three {
        display: none; } }
  .network-boxes-bottom .network-boxes-bottom-row-four {
    width: 205px;
    height: 205px;
    background-color: #91a8b1; }
  .network-boxes-bottom .network-boxes-bottom-row-five {
    width: 205px;
    height: 205px;
    background-color: #91a8b1; }

.network-title {
  font-size: 72px;
  margin-left: -106px; }
  @media only screen and (max-width: 425px) {
    .network-title {
      margin-left: -197px; } }

i.big.arrow.alternate.circle.up.outline.icon {
  padding-bottom: 45px; }

.bottom-parallax-nav {
  margin-left: 100px; }

.bottom-section-parallax {
  background: #000; }

.number-five {
  display: flex;
  align-items: center;
  flex-direction: row;
  align-content: space-between;
  color: white; }
  .number-five .line-five {
    border: .5px solid white;
    width: 40px;
    justify-self: center; }
  .number-five .line-number-five {
    margin-top: 0px;
    padding-left: 15px; }

.intro {
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  line-height: 1.5;
  font-style: normal; }
  .intro .intro-paragraph {
    padding: 0 1.5rem;
    padding-top: 14rem;
    overflow: hidden;
    font-weight: 400;
    max-width: 440px;
    padding-bottom: 10rem; }
    @media only screen and (max-width: 880px) {
      .intro .intro-paragraph {
        padding-top: 4rem;
        padding-bottom: 0;
        margin: 0 auto;
        padding-left: 0;
        padding-right: 0; } }
  .intro .intro-parallax {
    color: #141414;
    font-size: 17rem;
    height: 20rem;
    text-transform: uppercase;
    overflow: hidden;
    width: 3000px;
    font-family: "Alegreya Sans", sans-serif; }
  .intro #bg-screen .intro-bottom-section-left {
    font-weight: 900; }
    @media only screen and (max-width: 768px) {
      .intro #bg-screen .intro-bottom-section-left {
        margin: 0 auto;
        margin-right: 0px;
        font-weight: 900; } }
  .intro .intro-bottom {
    padding: 0 1.5rem;
    margin: auto;
    padding-top: 6rem;
    padding-bottom: 2rem;
    overflow: hidden; }
    @media only screen and (max-width: 768px) {
      .intro .intro-bottom {
        padding-top: 3rem; } }
    .intro .intro-bottom .intro-bottom-section {
      display: flex;
      padding-top: 4rem;
      padding-bottom: 5rem; }
      .intro .intro-bottom .intro-bottom-section h1 {
        line-height: 2;
        font-weight: 900;
        font-size: 20px; }
      .intro .intro-bottom .intro-bottom-section .intro-bottom-section-left {
        min-width: 228.13px;
        margin-right: 3rem;
        font-weight: 900; }
        @media only screen and (min-width: 1440px) {
          .intro .intro-bottom .intro-bottom-section .intro-bottom-section-left {
            min-width: 338px; } }
        @media only screen and (min-width: 768px) {
          .intro .intro-bottom .intro-bottom-section .intro-bottom-section-left {
            margin-right: 0 !important;
            margin: 0 auto; } }
      .intro .intro-bottom .intro-bottom-section #optional-website {
        color: #46e0b4;
        font-weight: 900; }
        .intro .intro-bottom .intro-bottom-section #optional-website:hover {
          color: #E3545f; }
      .intro .intro-bottom .intro-bottom-section .intro-bottom-section-rigtht {
        padding: 0 1.5rem;
        width: 500px; }
  .intro .intro-images-container {
    display: flex;
    max-width: 1280px;
    padding: 0 1.5rem;
    margin: auto;
    overflow: hidden; }
    .intro .intro-images-container .intro-images {
      width: 719px;
      height: 431.5px;
      margin-left: 3rem; }
      @media only screen and (max-width: 768px) {
        .intro .intro-images-container .intro-images {
          width: 100%;
          margin-left: 0; } }
  .intro .intro-bottom-timeline {
    font-size: 1rem;
    display: flex;
    justify-content: space-between;
    line-height: 3; }

.scroll {
  -webkit-transform: translateX(-200rem);
          transform: translateX(-200rem);
  -webkit-transition: all ease-in 4s;
  transition: all ease-in 4s;
  opacity: 1; }

#img {
  opacity: 0;
  -webkit-animation: reveal-text 1s linear forwards;
          animation: reveal-text 1s linear forwards;
  -webkit-animation-delay: 1.4s;
          animation-delay: 1.4s;
  width: 350;
  height: 465;
  border-radius: 10px; }

@-webkit-keyframes reveal-animation {
  0% {
    left: 0%;
    widows: 0%; }
  50% {
    left: 0%;
    width: 100%; }
  100% {
    left: 100%;
    width: 0%; } }

@keyframes reveal-animation {
  0% {
    left: 0%;
    widows: 0%; }
  50% {
    left: 0%;
    width: 100%; }
  100% {
    left: 100%;
    width: 0%; } }

@-webkit-keyframes reveal-text {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes reveal-text {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes reveal-image {
  0% {
    left: 0%;
    width: 0%; }
  50% {
    left: 0%;
    width: 46%; }
  100% {
    left: 46%;
    width: 0%; } }

@keyframes reveal-image {
  0% {
    left: 0%;
    width: 0%; }
  50% {
    left: 0%;
    width: 46%; }
  100% {
    left: 46%;
    width: 0%; } }

@media only screen and (max-width: 880px) {
  .main-profile-container {
    flex-direction: column;
    position: relative; }
  .discover {
    display: none; }
  .title {
    display: none; } }

@media only screen and (max-width: 880px) {
  .profile-container {
    display: flex;
    justify-content: center;
    margin: 0 auto; } }

@media only screen and (max-width: 768px) {
  .main-profile-container {
    flex-direction: column; }
  .bottom-container {
    padding: 0px; }
  .intro {
    margin: 0 auto; }
  .intro-bottom-section {
    flex-direction: column; }
  .intro-bottom-section-left {
    font-weight: 900;
    margin-left: 0;
    margin-right: 0px !important; }
  .intro-bottom-timeline {
    flex-direction: column; }
  .intro-images-container {
    flex-direction: column; }
  .intro-images {
    margin-top: 2rem; } }

.font {
  color: #03d999; }

@font-face {
  font-family: road_rage;
  src: url("/fonts/Road_Rage.otf"); }

@media (max-width: 800px) {
  #header-home {
    height: 30rem; }
    #header-home .header-content {
      padding-top: 5rem; } }

@media (max-width: 500px) {
  #main-nav {
    flex-direction: column;
    align-items: center; }
    #main-nav li {
      padding: 1rem; }
  #header-home {
    height: 10rem;
    border-bottom: 3px solid #ffbc00;
    background-position: 20% 30%; }
    #header-home .header-content {
      display: none; }
  #header-inner {
    height: 10rem; }
  #home-a .specials,
  #home-b .stats,
  #home-c .process,
  #about-d .testimonials,
  #contact-b .contact-info,
  .items {
    grid-template-columns: 1fr; }
  #home-a .specials div {
    border-bottom: 1px #f4f4f4 solid;
    padding-bottom: 1rem; }
    #home-a .specials div:last-child {
      border: none;
      padding-bottom: 0; }
  #home-b .stats div {
    padding: 2rem 0 1rem 0; }
  #about-a .about-info {
    grid-template-areas: 'bioimage' 'bio' 'aw1' 'aw2' 'aw3'; }
  #about-c {
    display: none; }
  #contact-a .text-fields {
    grid-template-areas: 'name' 'subject' 'email' 'phone' 'message'; }
  #contact-b div {
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px #555 solid; }
    #contact-b div:last-child {
      border: none;
      margin-bottom: 0;
      padding-bottom: 0; }
  #contact-c h1 {
    font-size: 2rem; }
  #main-footer {
    height: 7rem; }
    #main-footer .footer-content {
      flex-direction: column;
      padding: 1rem;
      height: 5rem; } }

@media (max-height: 580px) {
  #header-home .header-content {
    padding-top: 3rem; } }

@media (max-height: 330px) {
  #header-home .header-content h1 {
    font-size: 2rem; } }

.items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }

.item {
  position: relative;
  background: #efefef;
  box-shadow: 0.3rem 0.4rem 0.4rem rgba(0, 0, 0, 0.4);
  overflow: hidden; }
  .item::after {
    content: '';
    position: absolute;
    display: block;
    background: inherit;
    opacity: 0.9;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-transform: scale(2) translateX(-75%) translateY(-75%) rotate(-28deg);
            transform: scale(2) translateX(-75%) translateY(-75%) rotate(-28deg);
    -webkit-transition: -webkit-transform 3s cubic-bezier(0.2, 1, 0.3, 1);
    transition: -webkit-transform 3s cubic-bezier(0.2, 1, 0.3, 1);
    transition: transform 3s cubic-bezier(0.2, 1, 0.3, 1);
    transition: transform 3s cubic-bezier(0.2, 1, 0.3, 1), -webkit-transform 3s cubic-bezier(0.2, 1, 0.3, 1); }
  .item:hover:after {
    -webkit-transform: scale(2) translateX(0) translateY(0) rotate(-28deg);
            transform: scale(2) translateX(0) translateY(0) rotate(-28deg); }
  .item:hover .item-image {
    -webkit-transform: scale(1.2);
            transform: scale(1.2); }
  .item:hover .item-text {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  .item-image {
    height: auto;
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    display: block;
    -webkit-transition: -webkit-transform 750ms cubic-bezier(0.2, 1, 0.3, 1);
    transition: -webkit-transform 750ms cubic-bezier(0.2, 1, 0.3, 1);
    transition: transform 750ms cubic-bezier(0.2, 1, 0.3, 1);
    transition: transform 750ms cubic-bezier(0.2, 1, 0.3, 1), -webkit-transform 750ms cubic-bezier(0.2, 1, 0.3, 1); }
  .item-image:before {
    content: '';
    display: block;
    padding-top: 75%;
    overflow: hidden; }
  .item-image img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    line-height: 0; }
  .item-text {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    text-align: center;
    z-index: 1;
    color: #000;
    -webkit-transform: translateY(-20%);
            transform: translateY(-20%);
    -webkit-transition: opacity 500ms cubic-bezier(0.2, 1, 0.3, 1), -webkit-transform 500ms cubic-bezier(0.2, 1, 0.3, 1);
    transition: opacity 500ms cubic-bezier(0.2, 1, 0.3, 1), -webkit-transform 500ms cubic-bezier(0.2, 1, 0.3, 1);
    transition: opacity 500ms cubic-bezier(0.2, 1, 0.3, 1), transform 500ms cubic-bezier(0.2, 1, 0.3, 1);
    transition: opacity 500ms cubic-bezier(0.2, 1, 0.3, 1), transform 500ms cubic-bezier(0.2, 1, 0.3, 1), -webkit-transform 500ms cubic-bezier(0.2, 1, 0.3, 1);
    -webkit-transition-delay: 300ms;
            transition-delay: 300ms; }
  .item-text-wrap {
    position: absolute;
    width: 100%;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); }
  .item-text-title {
    font-size: 2rem;
    padding: 0 1rem;
    margin: 5px 0 0 0; }
  .item-text-category {
    text-transform: uppercase;
    font-size: 1.2rem;
    opacity: 0.7;
    margin: 0; }

@media only screen and (max-width: 1280px) {
  .main-container {
    max-width: 950px;
    padding: 0 1.5rem;
    margin: auto;
    overflow: hidden; } }

@media only screen and (min-width: 1280px) {
  .main-container {
    max-width: 1100px;
    padding: 0 1.5rem;
    margin: auto;
    overflow: hidden; } }

.btn-main, .btn-light, .btn-dark {
  display: inline-block;
  padding: 0.8rem 2rem;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  border: none;
  cursor: pointer; }

.btn-main {
  color: #333;
  background-color: #ffbc00; }

.btn-light {
  color: #333;
  background-color: #f4f4f4; }

.btn-dark {
  color: #f4f4f4;
  background-color: #333; }

.bg-main {
  background: #ffbc00;
  color: #fff; }

.bg-dark {
  background: #333;
  color: #fff; }

.bg-light {
  background: #f4f4f4;
  color: #000; }

.bg-medium {
  background: #ccc;
  color: #000; }

.lead {
  font-size: 1.3rem;
  margin-bottom: 2rem; }

.text-center {
  text-align: center; }

.py {
  padding-top: 5rem; }
  .py-1 {
    padding: 1rem 0; }
  .py-2 {
    padding: 2rem 0; }
  .py-3 {
    padding: 3rem 0; }
  .py-4 {
    padding: 4rem 0; }

.my-1 {
  margin: 1rem 0; }

.my-2 {
  margin: 2rem 0; }

.my-3 {
  margin: 3rem 0; }

.my-4 {
  margin: 4rem 0; }

.main-container-menu {
  display: flex;
  justify-content: space-between; }
  .main-container-menu .section-title {
    letter-spacing: 2px;
    font-size: 2rem;
    display: block;
    text-align: start;
    font-weight: 900;
    text-transform: uppercase;
    font-family: "Roboto", sans-serif; }
  .main-container-menu .menu {
    display: flex; }
    .main-container-menu .menu .menu-item {
      padding-left: 20px;
      letter-spacing: 2px; }

.section-subtitle- {
  display: block;
  text-align: start;
  padding-bottom: 3rem;
  color: #e0bd44; }

.bottom-line {
  height: .5px;
  width: 100%;
  background: #bbb;
  display: block;
  margin: 0 auto 1rem auto; }

.bottom-parallax-nav {
  margin-left: 100px; }
  @media only screen and (max-width: 768px) {
    .bottom-parallax-nav {
      margin-left: 0; } }
  @media only screen and (max-width: 694px) {
    .bottom-parallax-nav {
      margin: 0 auto; } }

#contact {
  background: #000; }

.bottom-section-parallax {
  background: #000;
  position: relative;
  top: 0;
  left: 0;
  margin: 0;
  width: 100%;
  overflow: hidden;
  bottom: 0;
  padding-bottom: 6rem !important;
  padding-top: 4rem !important;
  display: flex;
  margin: 0 auto;
  justify-content: space-between; }
  @media only screen and (max-width: 768px) {
    .bottom-section-parallax {
      width: 90%; } }
  @media only screen and (max-width: 694px) {
    .bottom-section-parallax {
      flex-direction: column;
      width: 100%; } }

.number-five {
  display: flex;
  align-items: center;
  flex-direction: row;
  align-content: space-between;
  color: white; }
  .number-five .line-five {
    border: .5px solid white;
    width: 40px;
    justify-self: center; }
  .number-five .line-number-five {
    margin-top: 0px;
    padding-left: 15px; }

.bottom-section {
  display: flex;
  flex-direction: column;
  margin-left: 53px; }
  @media only screen and (max-width: 425px) {
    .bottom-section {
      margin-left: 0; } }

.contact-top .contact-h-top {
  color: white;
  font-size: 4.5rem;
  line-height: 1.20455em;
  letter-spacing: -.03409em;
  font-weight: 900; }

.get-in-touch-div {
  display: flex;
  align-items: center;
  padding-top: 30px; }
  .get-in-touch-div .get-in {
    margin-left: 10px;
    margin-left: 10px; }
    .get-in-touch-div .get-in:hover {
      background: white;
      color: #1C262B; }
  .get-in-touch-div .get-in-touch {
    color: #000;
    background: #aef0d2;
    min-width: 2.5rem;
    padding-right: 1.88636rem;
    padding-left: 1.88636rem;
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 1.12rem;
    height: 37.63px; }
    .get-in-touch-div .get-in-touch:hover {
      background: white;
      color: #1C262B; }
  .get-in-touch-div .get-in-touch-phone {
    background: #aef0d2;
    width: 2.5rem;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-right: 5px;
    padding-left: 5px; }
    .get-in-touch-div .get-in-touch-phone .phone .icon {
      background: #aef0d2; }
  .get-in-touch-div .get-in-touch-map {
    background: #aef0d2;
    width: 2.5rem;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-right: 5px;
    padding-left: 5px;
    align-items: center;
    align-content: center;
    justify-content: center; }

.get-in-touch-paragraph .contact-info {
  color: #fff;
  padding-top: 20px;
  font-size: 15px; }

.bottom-section-middle {
  margin-top: 45px;
  margin-left: 154px;
  width: 238px;
  margin-top: 60px;
  display: flex;
  flex-direction: column; }
  @media only screen and (max-width: 768px) {
    .bottom-section-middle {
      margin-left: 3.5rem; } }
  @media only screen and (max-width: 694px) {
    .bottom-section-middle {
      margin: 0 auto;
      padding-top: 2rem;
      padding-left: 20px; } }
  @media only screen and (max-width: 425px) {
    .bottom-section-middle {
      margin: 0 auto;
      padding-left: 0; } }

.contact-top-middle {
  font-size: 15px; }

.contact-h-middle {
  color: white;
  padding-bottom: 9px; }

.contact-h-middle-discover {
  color: white;
  margin-top: -11px;
  font-weight: 100; }

.get-in-touch-div-middle {
  padding-top: 40px;
  display: flex;
  flex-direction: row; }

.get-in-touch-linkedin .linkedin {
  color: #aef0d2; }

.get-in-touch-google .google {
  color: #aef0d2; }

.sign-up {
  color: white;
  padding-top: 30px;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid white;
  padding-top: 30px;
  border-bottom-width: 1px;
  width: 190px; }
  .sign-up .download {
    font-size: 15px; }

i.icon.github:before {
  content: "\f09b";
  color: #aef0d2; }

.copyright {
  color: white;
  font-size: 15px;
  padding-top: 3rem;
  margin-bottom: 3rem; }

.get-padding {
  padding-left: 15px; }

.font {
  color: #03d999; }

@font-face {
  font-family: road_rage;
  src: url("/fonts/Road_Rage.otf"); }

@media (max-width: 800px) {
  #header-home {
    height: 30rem; }
    #header-home .header-content {
      padding-top: 5rem; } }

@media (max-width: 500px) {
  #main-nav {
    flex-direction: column;
    align-items: center; }
    #main-nav li {
      padding: 1rem; }
  #header-home {
    height: 10rem;
    border-bottom: 3px solid #ffbc00;
    background-position: 20% 30%; }
    #header-home .header-content {
      display: none; }
  #header-inner {
    height: 10rem; }
  #home-a .specials,
  #home-b .stats,
  #home-c .process,
  #about-d .testimonials,
  #contact-b .contact-info,
  .items {
    grid-template-columns: 1fr; }
  #home-a .specials div {
    border-bottom: 1px #f4f4f4 solid;
    padding-bottom: 1rem; }
    #home-a .specials div:last-child {
      border: none;
      padding-bottom: 0; }
  #home-b .stats div {
    padding: 2rem 0 1rem 0; }
  #about-a .about-info {
    grid-template-areas: 'bioimage' 'bio' 'aw1' 'aw2' 'aw3'; }
  #about-c {
    display: none; }
  #contact-a .text-fields {
    grid-template-areas: 'name' 'subject' 'email' 'phone' 'message'; }
  #contact-b div {
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px #555 solid; }
    #contact-b div:last-child {
      border: none;
      margin-bottom: 0;
      padding-bottom: 0; }
  #contact-c h1 {
    font-size: 2rem; }
  #main-footer {
    height: 7rem; }
    #main-footer .footer-content {
      flex-direction: column;
      padding: 1rem;
      height: 5rem; } }

@media (max-height: 580px) {
  #header-home .header-content {
    padding-top: 3rem; } }

@media (max-height: 330px) {
  #header-home .header-content h1 {
    font-size: 2rem; } }

.ProjectViewTop {
  max-width: 1000px;
  padding: 0 1.5rem;
  margin: auto;
  overflow: hidden;
  padding-top: 7rem;
  font-family: "Roboto", sans-serif; }
  @media only screen and (max-width: 425px) {
    .ProjectViewTop {
      padding: 0; } }
  @media only screen and (max-width: 768px) {
    .ProjectViewTop {
      padding-top: 0rem; } }
  .ProjectViewTop .top-container {
    width: 100%;
    height: 40vh; }
    @media only screen and (max-width: 768px) {
      .ProjectViewTop .top-container {
        height: 41vh; } }
  .ProjectViewTop .description {
    margin-left: 56px;
    padding-top: 25px; }
    @media only screen and (max-width: 425px) {
      .ProjectViewTop .description {
        margin-left: 0;
        padding-top: 1.3rem; } }
    .ProjectViewTop .description .paragraph {
      margin-top: 3%;
      line-height: 1.5rem;
      font-size: .9rem;
      font-family: "Roboto", sans-serif;
      width: 31rem; }
      @media only screen and (max-width: 425px) {
        .ProjectViewTop .description .paragraph {
          width: 15rem; } }
    .ProjectViewTop .description .title-one {
      font-size: 3rem; }
    .ProjectViewTop .description .secondary-line {
      border: .5px solid black;
      display: flex;
      width: 30px; }

.main-container {
  margin-left: 10%; }

.top-container {
  width: 100%;
  height: 40vh; }
  @media only screen and (min-width: 1440px) {
    .top-container {
      height: 26vh; } }
  .top-container .description {
    margin-left: 56px;
    padding-top: 25px; }
    @media only screen and (max-width: 425px) {
      .top-container .description {
        margin-left: 0;
        padding-top: 0; } }
  .top-container .secondary-line {
    border: .5px solid black;
    display: flex;
    width: 30px; }
  .top-container p {
    width: 43%;
    margin-top: 3%; }

.bottom-container {
  height: 50vh;
  max-width: 1000px;
  padding: 0 1.5rem;
  margin: auto; }
  @media only screen and (max-width: 425px) {
    .bottom-container {
      padding: 0;
      height: 60vh; } }
  .bottom-container .arrow {
    display: flex;
    flex-direction: column;
    cursor: pointer; }
    @media only screen and (max-width: 425px) {
      .bottom-container .arrow {
        z-index: 300;
        color: teal; } }
    .bottom-container .arrow i.icon.arrow.right {
      padding-left: inherit;
      padding-top: 5px; }
  .bottom-container .top-section-bottom-container {
    margin-left: 56px; }
    @media only screen and (max-width: 425px) {
      .bottom-container .top-section-bottom-container {
        margin-left: 0px; } }
  .bottom-container h1 {
    font-size: 92px;
    margin-top: -34px;
    z-index: 200;
    position: relative;
    color: black; }
    @media only screen and (max-width: 425px) {
      .bottom-container h1 {
        color: sienna; } }
  .bottom-container .ui.primary.button, .bottom-container .ui.primary.buttons .button {
    background-color: #aef0d2;
    color: black;
    text-shadow: none;
    background-image: none;
    -webkit-transition: all .2s ease-in .1s;
    transition: all .2s ease-in .1s;
    margin-top: 48px;
    margin-left: 126px;
    width: 12rem; }
    @media only screen and (max-width: 425px) {
      .bottom-container .ui.primary.button, .bottom-container .ui.primary.buttons .button {
        background-color: #aef0d2;
        color: black;
        text-shadow: none;
        background-image: none;
        -webkit-transition: all .2s ease-in .1s;
        transition: all .2s ease-in .1s;
        width: 12rem;
        margin-top: 2rem;
        margin-left: 0;
        display: block; } }
    .bottom-container .ui.primary.button:hover, .bottom-container .ui.primary.buttons .button:hover {
      background: #f9cbd1;
      color: #000; }
  .bottom-container .ui.button {
    border-radius: none; }

.number {
  display: flex;
  align-items: center;
  flex-direction: row;
  align-content: space-between; }
  .number .line {
    border: .5px solid black;
    width: 40px;
    justify-self: center; }
  .number h2 {
    margin-top: 0px;
    padding-left: 15px; }

.carousel {
  padding: 0px 70px;
  margin-top: -113px; }
  @media only screen and (max-width: 425px) {
    .carousel {
      padding: 0; } }

.carousel__container {
  overflow: hidden;
  height: 200px; }

.carousel__list {
  position: relative;
  display: flex;
  flex-wrap: nowrap; }

.carousel__list.unwrap {
  flex-wrap: nowrap; }

.carousel__item {
  flex: 1 0 300px;
  height: 200px;
  width: 350px;
  background-color: #ccc;
  text-align: center;
  line-height: 100px; }

.title-containter {
  padding: 0px 70px;
  width: 741; }
  @media only screen and (max-width: 425px) {
    .title-containter {
      padding: 0; } }
  @media only screen and (max-width: 425px) {
    .title-containter .titles {
      margin-left: 0px; } }

.title-wrapper {
  overflow: hidden;
  width: 741; }

.titles {
  display: flex;
  flex-direction: row;
  width: 741;
  margin-left: 57px; }
  .titles .general-title {
    flex: 1 0 400px;
    padding-top: 33px; }
  .titles h4 {
    margin-top: 0px;
    font-weight: 100; }
  .titles h3 {
    font-weight: 10360; }
  .titles .header-description a {
    color: black;
    -webkit-transition: all .12s ease-in-out;
    transition: all .12s ease-in-out;
    font-weight: 900; }
    .titles .header-description a:hover {
      color: #46e0b4; }

.imageView {
  width: 300px;
  height: 250px; }

#arrow-left-l {
  color: black; }
  #arrow-left-l:hover {
    color: #EB4E7A; }
  @media only screen and (max-width: 425px) {
    #arrow-left-l {
      z-index: 300;
      color: #000000;
      font-size: 2rem; } }

#arrow-right-r {
  color: black; }
  #arrow-right-r:hover {
    color: #EB4E7A; }
  @media only screen and (max-width: 425px) {
    #arrow-right-r {
      z-index: 300;
      color: #000000;
      font-size: 2rem; } }

.carousel__list {
  -webkit-animation-delay: 6s;
          animation-delay: 6s; }

.project-section {
  display: grid;
  grid-template-columns: 1fr 1fr; }
  .project-section .project-box {
    position: relative;
    padding: 30px;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    background-size: contain;
    width: 400px;
    height: 350px; }
    .project-section .project-box .grey-bg {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background: #efefef;
      -webkit-transition: all .3s ease-in-out;
      transition: all .3s ease-in-out;
      opacity: 0;
      overflow: hidden; }
      .project-section .project-box .grey-bg .project-info {
        position: absolute;
        padding: 18px;
        padding-left: 45px;
        top: 7rem;
        width: 100%; }
        @media only screen and (max-width: 425px) {
          .project-section .project-box .grey-bg .project-info {
            padding-left: 0; } }
        .project-section .project-box .grey-bg .project-info .project-details {
          position: relative;
          font-size: 1rem;
          text-transform: capitalize;
          margin: 0 0 1rem;
          -webkit-transform: translate3d(-400px, 0, 0);
                  transform: translate3d(-400px, 0, 0);
          -webkit-transition: all .3s ease-in;
          transition: all .3s ease-in; }
        .project-section .project-box .grey-bg .project-info .project-title {
          font-size: 1.3rem;
          text-transform: uppercase;
          font-weight: 800;
          -webkit-transition: all .3s ease-in .1s;
          transition: all .3s ease-in .1s;
          -webkit-transform: translate3d(-100px, 0, 0);
                  transform: translate3d(-100px, 0, 0);
          opacity: 0;
          font-weight: 900; }
        .project-section .project-box .grey-bg .project-info .long {
          position: absolute;
          bottom: 15px;
          right: 13px;
          font-size: 2rem;
          color: #000;
          -webkit-transform: translate3d(-30px, 0, 0);
                  transform: translate3d(-30px, 0, 0);
          -webkit-transition: all .1s ease-in-out;
          transition: all .1s ease-in-out; }
          @media only screen and (max-width: 425px) {
            .project-section .project-box .grey-bg .project-info .long {
              right: 156px; } }
      .project-section .project-box .grey-bg:hover {
        opacity: .9; }
        .project-section .project-box .grey-bg:hover .project-info .project-details {
          -webkit-transform: translate3d(0, 0, 0);
                  transform: translate3d(0, 0, 0);
          opacity: 1; }
        .project-section .project-box .grey-bg:hover .project-info .project-title {
          -webkit-transform: translate3d(0, 0, 0);
                  transform: translate3d(0, 0, 0);
          opacity: 1; }
        .project-section .project-box .grey-bg:hover .project-info .long {
          -webkit-transform: translate3d(0, 0, 0);
                  transform: translate3d(0, 0, 0); }

.font {
  color: #03d999; }

@font-face {
  font-family: road_rage;
  src: url("/fonts/Road_Rage.otf"); }

@media (max-width: 800px) {
  #header-home {
    height: 30rem; }
    #header-home .header-content {
      padding-top: 5rem; } }

@media (max-width: 500px) {
  #main-nav {
    flex-direction: column;
    align-items: center; }
    #main-nav li {
      padding: 1rem; }
  #header-home {
    height: 10rem;
    border-bottom: 3px solid #ffbc00;
    background-position: 20% 30%; }
    #header-home .header-content {
      display: none; }
  #header-inner {
    height: 10rem; }
  #home-a .specials,
  #home-b .stats,
  #home-c .process,
  #about-d .testimonials,
  #contact-b .contact-info,
  .items {
    grid-template-columns: 1fr; }
  #home-a .specials div {
    border-bottom: 1px #f4f4f4 solid;
    padding-bottom: 1rem; }
    #home-a .specials div:last-child {
      border: none;
      padding-bottom: 0; }
  #home-b .stats div {
    padding: 2rem 0 1rem 0; }
  #about-a .about-info {
    grid-template-areas: 'bioimage' 'bio' 'aw1' 'aw2' 'aw3'; }
  #about-c {
    display: none; }
  #contact-a .text-fields {
    grid-template-areas: 'name' 'subject' 'email' 'phone' 'message'; }
  #contact-b div {
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px #555 solid; }
    #contact-b div:last-child {
      border: none;
      margin-bottom: 0;
      padding-bottom: 0; }
  #contact-c h1 {
    font-size: 2rem; }
  #main-footer {
    height: 7rem; }
    #main-footer .footer-content {
      flex-direction: column;
      padding: 1rem;
      height: 5rem; } }

@media (max-height: 580px) {
  #header-home .header-content {
    padding-top: 3rem; } }

@media (max-height: 330px) {
  #header-home .header-content h1 {
    font-size: 2rem; } }

.navigation {
  -webkit-transform: translateX(-100vw);
          transform: translateX(-100vw);
  -webkit-transition: -webkit-transform 0.8s cubic-bezier(0.86, 0, 0.07, 1);
  transition: -webkit-transform 0.8s cubic-bezier(0.86, 0, 0.07, 1);
  transition: transform 0.8s cubic-bezier(0.86, 0, 0.07, 1);
  transition: transform 0.8s cubic-bezier(0.86, 0, 0.07, 1), -webkit-transform 0.8s cubic-bezier(0.86, 0, 0.07, 1);
  top: 0;
  left: 0;
  right: 0;
  left: 0;
  position: fixed;
  z-index: 10000000; }
  @media only screen and (min-width: 700px) {
    .navigation {
      display: none; } }
  .navigation__background {
    height: 100vh;
    width: 100vw;
    background: #c9af98; }
  .navigation__nav {
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1500;
    opacity: 1;
    width: 100%;
    -webkit-transition: all 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transition: all 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55); }
  .navigation__list {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    list-style: none;
    text-align: center;
    width: 100%; }
  .navigation__item {
    margin: 1rem; }
  .navigation__link:link, .navigation__link:visited {
    display: inline-block;
    font-size: 2rem;
    font-weight: 300;
    padding: 1rem 2rem;
    color: #3a4660;
    text-decoration: none;
    text-transform: uppercase;
    background-image: -webkit-linear-gradient(330deg, transparent 0%, transparent 50%, white 50%);
    background-image: linear-gradient(120deg, transparent 0%, transparent 50%, white 50%);
    background-size: 220%;
    -webkit-transition: all .4s;
    transition: all .4s; }
    .navigation__link:link span, .navigation__link:visited span {
      margin-right: 1.5rem;
      display: inline-block; }
  .navigation__link:hover, .navigation__link:active {
    background-position: 100%;
    color: #1C262B;
    -webkit-transform: translateX(1rem);
            transform: translateX(1rem); }

#active-menu {
  -webkit-transform: translateX(0vw);
          transform: translateX(0vw);
  display: block; }

.font {
  color: #03d999; }

@font-face {
  font-family: road_rage;
  src: url("/fonts/Road_Rage.otf"); }

@media (max-width: 800px) {
  #header-home {
    height: 30rem; }
    #header-home .header-content {
      padding-top: 5rem; } }

@media (max-width: 500px) {
  #main-nav {
    flex-direction: column;
    align-items: center; }
    #main-nav li {
      padding: 1rem; }
  #header-home {
    height: 10rem;
    border-bottom: 3px solid #ffbc00;
    background-position: 20% 30%; }
    #header-home .header-content {
      display: none; }
  #header-inner {
    height: 10rem; }
  #home-a .specials,
  #home-b .stats,
  #home-c .process,
  #about-d .testimonials,
  #contact-b .contact-info,
  .items {
    grid-template-columns: 1fr; }
  #home-a .specials div {
    border-bottom: 1px #f4f4f4 solid;
    padding-bottom: 1rem; }
    #home-a .specials div:last-child {
      border: none;
      padding-bottom: 0; }
  #home-b .stats div {
    padding: 2rem 0 1rem 0; }
  #about-a .about-info {
    grid-template-areas: 'bioimage' 'bio' 'aw1' 'aw2' 'aw3'; }
  #about-c {
    display: none; }
  #contact-a .text-fields {
    grid-template-areas: 'name' 'subject' 'email' 'phone' 'message'; }
  #contact-b div {
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px #555 solid; }
    #contact-b div:last-child {
      border: none;
      margin-bottom: 0;
      padding-bottom: 0; }
  #contact-c h1 {
    font-size: 2rem; }
  #main-footer {
    height: 7rem; }
    #main-footer .footer-content {
      flex-direction: column;
      padding: 1rem;
      height: 5rem; } }

@media (max-height: 580px) {
  #header-home .header-content {
    padding-top: 3rem; } }

@media (max-height: 330px) {
  #header-home .header-content h1 {
    font-size: 2rem; } }

@media only screen and (max-width: 1900px) {
  .wrapper-container-menu {
    display: flex;
    justify-content: space-between;
    max-width: 1100px;
    padding: 0 1.5rem;
    margin: auto;
    padding-top: 6rem;
    padding-bottom: 2rem;
    overflow: hidden;
    height: 57px; } }

@media only screen and (min-width: 1900px) {
  .wrapper-container-menu {
    display: flex;
    justify-content: space-between;
    max-width: 1100px;
    padding: 0 1.5rem;
    margin: auto;
    padding-top: 6rem;
    padding-bottom: 2rem;
    overflow: hidden;
    height: 57px;
    font-size: 1.3rem; } }

.wrapper-container-menu .logo {
  display: flex; }

.wrapper-container-menu .wrapper-title {
  letter-spacing: 2px;
  font-size: 2rem;
  font-weight: 900;
  font-family: "Roboto", sans-serif;
  position: fixed;
  top: 2.4rem; }
  @media only screen and (max-width: 320px) {
    .wrapper-container-menu .wrapper-title {
      top: 28px; } }

.wrapper-container-menu .wrapper-subtitle- {
  color: #e0bd44;
  align-self: flex-end; }

@media only screen and (max-width: 700px) {
  .wrapper-container-menu .wrapper-menu {
    display: none; } }

@media only screen and (min-width: 700px) {
  .wrapper-container-menu .wrapper-menu {
    display: flex;
    align-self: flex-end;
    padding-bottom: 2.5rem; } }

.wrapper-container-menu .wrapper-menu .wrapper-menu-item {
  padding-left: 20px;
  letter-spacing: 2px;
  font-family: "Roboto", sans-serif; }
  .wrapper-container-menu .wrapper-menu .wrapper-menu-item:hover {
    cursor: pointer;
    color: #E3545f; }

.sideways-right-nav-wrapper {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  height: 2rem;
  position: fixed;
  right: -16rem;
  width: 35rem;
  color: #000;
  top: 31.2rem;
  justify-content: center;
  align-items: center;
  display: none; }
  @media only screen and (min-width: 1900px) {
    .sideways-right-nav-wrapper {
      right: -15rem; } }
  @media screen and (max-width: 1400px) {
    .sideways-right-nav-wrapper {
      top: 25.2rem; } }
  @media screen and (max-width: 1100px) {
    .sideways-right-nav-wrapper {
      display: none; } }
  .sideways-right-nav-wrapper .rotate {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg); }
  .sideways-right-nav-wrapper .navigation-right-wrapper {
    list-style-type: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between; }
    @media only screen and (min-width: 1900px) {
      .sideways-right-nav-wrapper .navigation-right-wrapper {
        width: 40rem; } }
    .sideways-right-nav-wrapper .navigation-right-wrapper .navigation-link-right {
      color: #000; }
      .sideways-right-nav-wrapper .navigation-right-wrapper .navigation-link-right .navigation-list-right {
        font-weight: 1000;
        letter-spacing: 2px;
        font-size: 1.3rem;
        color: #000;
        font-family: "Roboto", sans-serif;
        color: #000; }
        @media only screen and (min-width: 1900px) {
          .sideways-right-nav-wrapper .navigation-right-wrapper .navigation-link-right .navigation-list-right {
            font-size: 1.8rem; } }

.sideways-left-nav-wrapper {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  height: 2rem;
  position: fixed;
  left: -9rem;
  color: #000;
  top: 28.2rem;
  justify-content: center;
  align-items: center;
  display: none; }
  @media screen and (max-width: 1400px) {
    .sideways-left-nav-wrapper {
      top: 22.2rem; } }
  @media screen and (max-width: 1100px) {
    .sideways-left-nav-wrapper {
      display: none; } }
  .sideways-left-nav-wrapper .navigation-left-wrapper {
    list-style-type: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 24rem;
    cursor: default; }
    .sideways-left-nav-wrapper .navigation-left-wrapper .navigation-link-left {
      color: #000;
      cursor: default; }
      .sideways-left-nav-wrapper .navigation-left-wrapper .navigation-link-left .navigation-list-left {
        font-weight: 1000;
        letter-spacing: 2px;
        font-size: 1.3rem;
        color: #000;
        font-family: "Roboto", sans-serif;
        color: #000;
        cursor: default; }
        @media only screen and (min-width: 1900px) {
          .sideways-left-nav-wrapper .navigation-left-wrapper .navigation-link-left .navigation-list-left {
            font-size: 1.8rem; } }

@media only screen and (max-width: 700px) {
  .Wrapper button {
    display: flex;
    align-self: center; } }

@media only screen and (min-width: 700px) {
  .Wrapper button {
    display: none; } }

#arrow__nav .arrow {
  font-size: 1.4rem; }

.m-fadeOut {
  visibility: hidden;
  opacity: 0;
  -webkit-transition: visibility 0s linear 300ms, opacity 300ms;
  transition: visibility 0s linear 300ms, opacity 300ms; }

.hamburger {
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  -webkit-transition-property: opacity, -webkit-filter;
  transition-property: opacity, -webkit-filter;
  transition-property: opacity, filter;
  transition-property: opacity, filter, -webkit-filter;
  -webkit-transition-duration: 0.15s;
          transition-duration: 0.15s;
  -webkit-transition-timing-function: linear;
          transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible; }

.hamburger-box {
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative; }

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px; }
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: 40px;
    height: 4px;
    background-color: #000;
    border-radius: 4px;
    position: absolute;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-duration: 0.15s;
            transition-duration: 0.15s;
    -webkit-transition-timing-function: ease;
            transition-timing-function: ease; }
  .hamburger-inner::before, .hamburger-inner::after {
    content: "";
    display: block; }
  .hamburger-inner::before {
    top: -10px; }
  .hamburger-inner::after {
    bottom: -10px; }

/*
   * Elastic
   */
.hamburger--elastic .hamburger-inner {
  top: 2px;
  -webkit-transition-duration: 0.275s;
          transition-duration: 0.275s;
  -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
          transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }
  .hamburger--elastic .hamburger-inner::before {
    top: 10px;
    -webkit-transition: opacity 0.125s 0.275s ease;
    transition: opacity 0.125s 0.275s ease; }
  .hamburger--elastic .hamburger-inner::after {
    top: 20px;
    -webkit-transition: -webkit-transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transition: -webkit-transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55), -webkit-transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55); }

.hamburger--elastic.is-active .hamburger-inner {
  -webkit-transform: translate3d(0, 10px, 0) rotate(135deg);
          transform: translate3d(0, 10px, 0) rotate(135deg);
  -webkit-transition-delay: 0.075s;
          transition-delay: 0.075s; }
  .hamburger--elastic.is-active .hamburger-inner::before {
    -webkit-transition-delay: 0s;
            transition-delay: 0s;
    opacity: 0; }
  .hamburger--elastic.is-active .hamburger-inner::after {
    -webkit-transform: translate3d(0, -20px, 0) rotate(-270deg);
            transform: translate3d(0, -20px, 0) rotate(-270deg);
    -webkit-transition-delay: 0.075s;
            transition-delay: 0.075s; }

.font {
  color: #03d999; }

@font-face {
  font-family: road_rage;
  src: url("/fonts/Road_Rage.otf"); }

@media (max-width: 800px) {
  #header-home {
    height: 30rem; }
    #header-home .header-content {
      padding-top: 5rem; } }

@media (max-width: 500px) {
  #main-nav {
    flex-direction: column;
    align-items: center; }
    #main-nav li {
      padding: 1rem; }
  #header-home {
    height: 10rem;
    border-bottom: 3px solid #ffbc00;
    background-position: 20% 30%; }
    #header-home .header-content {
      display: none; }
  #header-inner {
    height: 10rem; }
  #home-a .specials,
  #home-b .stats,
  #home-c .process,
  #about-d .testimonials,
  #contact-b .contact-info,
  .items {
    grid-template-columns: 1fr; }
  #home-a .specials div {
    border-bottom: 1px #f4f4f4 solid;
    padding-bottom: 1rem; }
    #home-a .specials div:last-child {
      border: none;
      padding-bottom: 0; }
  #home-b .stats div {
    padding: 2rem 0 1rem 0; }
  #about-a .about-info {
    grid-template-areas: 'bioimage' 'bio' 'aw1' 'aw2' 'aw3'; }
  #about-c {
    display: none; }
  #contact-a .text-fields {
    grid-template-areas: 'name' 'subject' 'email' 'phone' 'message'; }
  #contact-b div {
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px #555 solid; }
    #contact-b div:last-child {
      border: none;
      margin-bottom: 0;
      padding-bottom: 0; }
  #contact-c h1 {
    font-size: 2rem; }
  #main-footer {
    height: 7rem; }
    #main-footer .footer-content {
      flex-direction: column;
      padding: 1rem;
      height: 5rem; } }

@media (max-height: 580px) {
  #header-home .header-content {
    padding-top: 3rem; } }

@media (max-height: 330px) {
  #header-home .header-content h1 {
    font-size: 2rem; } }

.font {
  color: #03d999; }

@font-face {
  font-family: road_rage;
  src: url("/fonts/Road_Rage.otf"); }

@media (max-width: 800px) {
  #header-home {
    height: 30rem; }
    #header-home .header-content {
      padding-top: 5rem; } }

@media (max-width: 500px) {
  #main-nav {
    flex-direction: column;
    align-items: center; }
    #main-nav li {
      padding: 1rem; }
  #header-home {
    height: 10rem;
    border-bottom: 3px solid #ffbc00;
    background-position: 20% 30%; }
    #header-home .header-content {
      display: none; }
  #header-inner {
    height: 10rem; }
  #home-a .specials,
  #home-b .stats,
  #home-c .process,
  #about-d .testimonials,
  #contact-b .contact-info,
  .items {
    grid-template-columns: 1fr; }
  #home-a .specials div {
    border-bottom: 1px #f4f4f4 solid;
    padding-bottom: 1rem; }
    #home-a .specials div:last-child {
      border: none;
      padding-bottom: 0; }
  #home-b .stats div {
    padding: 2rem 0 1rem 0; }
  #about-a .about-info {
    grid-template-areas: 'bioimage' 'bio' 'aw1' 'aw2' 'aw3'; }
  #about-c {
    display: none; }
  #contact-a .text-fields {
    grid-template-areas: 'name' 'subject' 'email' 'phone' 'message'; }
  #contact-b div {
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px #555 solid; }
    #contact-b div:last-child {
      border: none;
      margin-bottom: 0;
      padding-bottom: 0; }
  #contact-c h1 {
    font-size: 2rem; }
  #main-footer {
    height: 7rem; }
    #main-footer .footer-content {
      flex-direction: column;
      padding: 1rem;
      height: 5rem; } }

@media (max-height: 580px) {
  #header-home .header-content {
    padding-top: 3rem; } }

@media (max-height: 330px) {
  #header-home .header-content h1 {
    font-size: 2rem; } }

.items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }

.item {
  position: relative;
  background: #efefef;
  box-shadow: 0.3rem 0.4rem 0.4rem rgba(0, 0, 0, 0.4);
  overflow: hidden; }
  .item::after {
    content: '';
    position: absolute;
    display: block;
    background: inherit;
    opacity: 0.9;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-transform: scale(2) translateX(-75%) translateY(-75%) rotate(-28deg);
            transform: scale(2) translateX(-75%) translateY(-75%) rotate(-28deg);
    -webkit-transition: -webkit-transform 3s cubic-bezier(0.2, 1, 0.3, 1);
    transition: -webkit-transform 3s cubic-bezier(0.2, 1, 0.3, 1);
    transition: transform 3s cubic-bezier(0.2, 1, 0.3, 1);
    transition: transform 3s cubic-bezier(0.2, 1, 0.3, 1), -webkit-transform 3s cubic-bezier(0.2, 1, 0.3, 1); }
  .item:hover:after {
    -webkit-transform: scale(2) translateX(0) translateY(0) rotate(-28deg);
            transform: scale(2) translateX(0) translateY(0) rotate(-28deg); }
  .item:hover .item-image {
    -webkit-transform: scale(1.2);
            transform: scale(1.2); }
  .item:hover .item-text {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  .item-image {
    height: auto;
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    display: block;
    -webkit-transition: -webkit-transform 750ms cubic-bezier(0.2, 1, 0.3, 1);
    transition: -webkit-transform 750ms cubic-bezier(0.2, 1, 0.3, 1);
    transition: transform 750ms cubic-bezier(0.2, 1, 0.3, 1);
    transition: transform 750ms cubic-bezier(0.2, 1, 0.3, 1), -webkit-transform 750ms cubic-bezier(0.2, 1, 0.3, 1); }
  .item-image:before {
    content: '';
    display: block;
    padding-top: 75%;
    overflow: hidden; }
  .item-image img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    line-height: 0; }
  .item-text {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    text-align: center;
    z-index: 1;
    color: #000;
    -webkit-transform: translateY(-20%);
            transform: translateY(-20%);
    -webkit-transition: opacity 500ms cubic-bezier(0.2, 1, 0.3, 1), -webkit-transform 500ms cubic-bezier(0.2, 1, 0.3, 1);
    transition: opacity 500ms cubic-bezier(0.2, 1, 0.3, 1), -webkit-transform 500ms cubic-bezier(0.2, 1, 0.3, 1);
    transition: opacity 500ms cubic-bezier(0.2, 1, 0.3, 1), transform 500ms cubic-bezier(0.2, 1, 0.3, 1);
    transition: opacity 500ms cubic-bezier(0.2, 1, 0.3, 1), transform 500ms cubic-bezier(0.2, 1, 0.3, 1), -webkit-transform 500ms cubic-bezier(0.2, 1, 0.3, 1);
    -webkit-transition-delay: 300ms;
            transition-delay: 300ms; }
  .item-text-wrap {
    position: absolute;
    width: 100%;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); }
  .item-text-title {
    font-size: 2rem;
    padding: 0 1rem;
    margin: 5px 0 0 0; }
  .item-text-category {
    text-transform: uppercase;
    font-size: 1.2rem;
    opacity: 0.7;
    margin: 0; }

@media only screen and (max-width: 1280px) {
  .main-container {
    max-width: 950px;
    padding: 0 1.5rem;
    margin: auto;
    overflow: hidden; } }

@media only screen and (min-width: 1280px) {
  .main-container {
    max-width: 1100px;
    padding: 0 1.5rem;
    margin: auto;
    overflow: hidden; } }

.btn-main, .btn-light, .btn-dark {
  display: inline-block;
  padding: 0.8rem 2rem;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  border: none;
  cursor: pointer; }

.btn-main {
  color: #333;
  background-color: #ffbc00; }

.btn-light {
  color: #333;
  background-color: #f4f4f4; }

.btn-dark {
  color: #f4f4f4;
  background-color: #333; }

.bg-main {
  background: #ffbc00;
  color: #fff; }

.bg-dark {
  background: #333;
  color: #fff; }

.bg-light {
  background: #f4f4f4;
  color: #000; }

.bg-medium {
  background: #ccc;
  color: #000; }

.lead {
  font-size: 1.3rem;
  margin-bottom: 2rem; }

.text-center {
  text-align: center; }

.py {
  padding-top: 5rem; }
  .py-1 {
    padding: 1rem 0; }
  .py-2 {
    padding: 2rem 0; }
  .py-3 {
    padding: 3rem 0; }
  .py-4 {
    padding: 4rem 0; }

.my-1 {
  margin: 1rem 0; }

.my-2 {
  margin: 2rem 0; }

.my-3 {
  margin: 3rem 0; }

.my-4 {
  margin: 4rem 0; }

.main-container-menu {
  display: flex;
  justify-content: space-between; }
  .main-container-menu .section-title {
    letter-spacing: 2px;
    font-size: 2rem;
    display: block;
    text-align: start;
    font-weight: 900;
    text-transform: uppercase;
    font-family: "Roboto", sans-serif; }
  .main-container-menu .menu {
    display: flex; }
    .main-container-menu .menu .menu-item {
      padding-left: 20px;
      letter-spacing: 2px; }

.section-subtitle- {
  display: block;
  text-align: start;
  padding-bottom: 3rem;
  color: #e0bd44; }

.bottom-line {
  height: .5px;
  width: 100%;
  background: #bbb;
  display: block;
  margin: 0 auto 1rem auto; }

#item {
  width: 286px;
  height: 198px; }
  #item #item-image {
    width: 286px;
    height: 198px; }

.item {
  position: relative;
  background: #efefef;
  overflow: hidden;
  margin: .5rem;
  box-shadow: 0.3rem 0.4rem 0.4rem rgba(0, 0, 0, 0.4); }
  .item:after {
    content: '';
    position: absolute;
    display: block;
    background: inherit;
    opacity: .9;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-transform: scale(2) translateX(-75%) translateY(-75%) rotate(-28deg);
            transform: scale(2) translateX(-75%) translateY(-75%) rotate(-28deg);
    -webkit-transition: -webkit-transform 3s cubic-bezier(0.2, 1, 0.3, 1);
    transition: -webkit-transform 3s cubic-bezier(0.2, 1, 0.3, 1);
    transition: transform 3s cubic-bezier(0.2, 1, 0.3, 1);
    transition: transform 3s cubic-bezier(0.2, 1, 0.3, 1), -webkit-transform 3s cubic-bezier(0.2, 1, 0.3, 1);
    transition: transform 3s cubic-bezier(0.2, 1, 0.3, 1); }
  .item:hover::after {
    -webkit-transform: scale(2) translateX(0) translateY(0) rotate(-28deg);
            transform: scale(2) translateX(0) translateY(0) rotate(-28deg); }

.secondary-title {
  font-size: 3rem;
  font-weight: 600;
  display: block;
  padding-top: 3rem;
  padding-bottom: 2rem; }

.tertiary-title {
  letter-spacing: 3px;
  font-size: 2rem;
  font-weight: 600;
  text-transform: uppercase;
  display: block;
  padding-bottom: 4rem;
  font-family: "Roboto", sans-serif;
  color: #e0bd44; }

.secondary-bottom-line {
  height: .5px;
  width: 100%;
  background: #bbb;
  display: block;
  margin: 0 auto 1rem auto;
  margin-bottom: 3rem;
  height: 1px; }

.individual-project .individual-project-container {
  padding-top: 3rem; }
  @media only screen and (min-width: 1440px) {
    .individual-project .individual-project-container {
      padding-bottom: 5rem; } }
  .individual-project .individual-project-container .individual-project-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 2rem;
    padding-right: 2rem; }
    @media only screen and (max-width: 880px) {
      .individual-project .individual-project-container .individual-project-top {
        flex-direction: column; } }
    .individual-project .individual-project-container .individual-project-top .individual-project-info {
      display: flex;
      flex-direction: column;
      align-items: end;
      padding-top: 1.5rem;
      padding-bottom: 3rem; }
      @media only screen and (max-width: 880px) {
        .individual-project .individual-project-container .individual-project-top .individual-project-info {
          order: 2; } }
      .individual-project .individual-project-container .individual-project-top .individual-project-info .individual-project-title {
        letter-spacing: 2px;
        font-size: 2rem;
        display: block;
        text-align: start;
        font-weight: 900;
        text-transform: uppercase;
        font-family: "Roboto", sans-serif; }
      .individual-project .individual-project-container .individual-project-top .individual-project-info .individual-project-description {
        text-align: -webkit-auto;
        max-width: 400px;
        color: #80828a;
        padding-top: 2rem;
        padding-bottom: 3rem;
        line-height: 1.5; }
      .individual-project .individual-project-container .individual-project-top .individual-project-info .individual-project-icon {
        display: flex; }
        .individual-project .individual-project-container .individual-project-top .individual-project-info .individual-project-icon .individual-project-link {
          font-weight: 900;
          padding-bottom: 1rem; }
        .individual-project .individual-project-container .individual-project-top .individual-project-info .individual-project-icon i:hover {
          color: #202020;
          cursor: pointer; }
        .individual-project .individual-project-container .individual-project-top .individual-project-info .individual-project-icon .long {
          font-size: 1.5rem;
          margin-top: -5px;
          margin-left: 1rem;
          color: #ffbc00;
          font-size: 1.5rem; }
        .individual-project .individual-project-container .individual-project-top .individual-project-info .individual-project-icon:hover {
          color: #202020;
          cursor: pointer; }
    .individual-project .individual-project-container .individual-project-top .individual-project-src {
      color: #2A5DB0;
      font-size: .7rem; }

.individual-project .individual-project-date-info {
  text-align: -webkit-auto;
  line-height: 1.6;
  font-family: "Roboto", sans-serif; }
  @media only screen and (max-width: 880px) {
    .individual-project .individual-project-date-info {
      order: 1; } }
  .individual-project .individual-project-date-info .individual-project-date {
    font-weight: 700; }
  .individual-project .individual-project-date-info .individual-project-year {
    color: #80828a; }

.individual-project .individual-project-tech {
  font-family: "Roboto", sans-serif; }
  .individual-project .individual-project-tech .individual-project-tech-title {
    font-weight: 700; }
  .individual-project .individual-project-tech .individual-project-technologies {
    color: #80828a; }
    .individual-project .individual-project-tech .individual-project-technologies a {
      color: #80828a; }
      .individual-project .individual-project-tech .individual-project-technologies a:hover {
        color: red !important; }

.individual-project .individual-project-image {
  padding: 4rem;
  background: #1d1e22;
  -webkit-transition: all .35s ease-out;
  transition: all .35s ease-out; }
  @media only screen and (max-width: 425px) {
    .individual-project .individual-project-image {
      padding: 2rem; } }
  .individual-project .individual-project-image:hover {
    padding: 5rem;
    background: #000000;
    margin: 1rem; }
    @media only screen and (max-width: 425px) {
      .individual-project .individual-project-image:hover {
        padding: 2rem; } }

.top-separator {
  display: flex;
  text-align: start;
  margin-bottom: 7rem;
  margin-top: 1.7rem;
  justify-content: center; }
  .top-separator .top-tertiary-line {
    align-self: center;
    background: black;
    width: 5rem;
    height: 3px;
    border-radius: 30rem; }
  .top-separator .top-separator-title {
    font-size: 92px;
    z-index: 200;
    color: #202020;
    font-family: "Rozha One", serif;
    font-weight: 100; }

.separator {
  display: flex;
  text-align: start;
  margin-bottom: 9rem;
  padding-right: 28rem;
  margin-top: 7rem; }
  @media only screen and (max-width: 880px) {
    .separator {
      padding-right: 0; } }
  @media only screen and (max-width: 425px) {
    .separator {
      margin-bottom: 3rem; } }
  .separator .tertiary-line {
    align-self: center;
    background: black;
    width: 5rem;
    height: 3px;
    border-radius: 30rem; }
  .separator .separator-title {
    font-size: 92px;
    z-index: 200;
    color: #202020; }
    @media only screen and (max-width: 425px) {
      .separator .separator-title {
        font-size: 78px; } }

.RandomQuote {
  padding: 70px 0; }
  .RandomQuote .container span, .RandomQuote .container h1 {
    color: #000;
    font-family: "Rozha One", serif;
    text-transform: capitalize;
    text-align: center; }
  .RandomQuote .container h1 {
    font-size: 3rem;
    max-width: 850px;
    margin: 0 auto 50px;
    line-height: 1.3; }
  .RandomQuote .container span {
    font-size: 2rem;
    font-weight: 300;
    display: block;
    color: #e0bd44; }

#long-arrow:hover {
  color: red !important; }

.font {
  color: #03d999; }

@font-face {
  font-family: road_rage;
  src: url("/fonts/Road_Rage.otf"); }

@media (max-width: 800px) {
  #header-home {
    height: 30rem; }
    #header-home .header-content {
      padding-top: 5rem; } }

@media (max-width: 500px) {
  #main-nav {
    flex-direction: column;
    align-items: center; }
    #main-nav li {
      padding: 1rem; }
  #header-home {
    height: 10rem;
    border-bottom: 3px solid #ffbc00;
    background-position: 20% 30%; }
    #header-home .header-content {
      display: none; }
  #header-inner {
    height: 10rem; }
  #home-a .specials,
  #home-b .stats,
  #home-c .process,
  #about-d .testimonials,
  #contact-b .contact-info,
  .items {
    grid-template-columns: 1fr; }
  #home-a .specials div {
    border-bottom: 1px #f4f4f4 solid;
    padding-bottom: 1rem; }
    #home-a .specials div:last-child {
      border: none;
      padding-bottom: 0; }
  #home-b .stats div {
    padding: 2rem 0 1rem 0; }
  #about-a .about-info {
    grid-template-areas: 'bioimage' 'bio' 'aw1' 'aw2' 'aw3'; }
  #about-c {
    display: none; }
  #contact-a .text-fields {
    grid-template-areas: 'name' 'subject' 'email' 'phone' 'message'; }
  #contact-b div {
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px #555 solid; }
    #contact-b div:last-child {
      border: none;
      margin-bottom: 0;
      padding-bottom: 0; }
  #contact-c h1 {
    font-size: 2rem; }
  #main-footer {
    height: 7rem; }
    #main-footer .footer-content {
      flex-direction: column;
      padding: 1rem;
      height: 5rem; } }

@media (max-height: 580px) {
  #header-home .header-content {
    padding-top: 3rem; } }

@media (max-height: 330px) {
  #header-home .header-content h1 {
    font-size: 2rem; } }

html, body {
  width: 100vw !important; }

body {
  overflow-x: hidden !important; }

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1;
  color: #202020;
  background: white; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after {
  content: '';
  content: none; }

q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit; }

a {
  -webkit-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
  cursor: pointer; }
  a:hover {
    color: #911A20; }

.cursor {
  width: 40px;
  height: 40px;
  border: 1.5px solid #ef3f4a;
  border-radius: 50%;
  position: absolute;
  -webkit-transition-duration: 200ms;
          transition-duration: 200ms;
  -webkit-transition-timing-function: ease-out;
          transition-timing-function: ease-out;
  -webkit-animation: cursorAnim .5s infinite alternate;
          animation: cursorAnim .5s infinite alternate;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-items: center;
  align-content: center;
  justify-content: center; }

.cursor--small {
  width: 5px;
  height: 5px;
  left: -2.5px;
  top: -2.5px;
  border-radius: 50%;
  z-index: 15551000;
  background: #ef3f4a; }

@-webkit-keyframes cursorAnim {
  from {
    -webkit-transform: scale(1);
            transform: scale(1); }
  to {
    -webkit-transform: scale(0.7);
            transform: scale(0.7); } }

@keyframes cursorAnim {
  from {
    -webkit-transform: scale(1);
            transform: scale(1); }
  to {
    -webkit-transform: scale(0.7);
            transform: scale(0.7); } }

@-webkit-keyframes cursorAnim2 {
  from {
    -webkit-transform: scale(1);
            transform: scale(1); }
  to {
    -webkit-transform: scale(0.4);
            transform: scale(0.4); } }

@keyframes cursorAnim2 {
  from {
    -webkit-transform: scale(1);
            transform: scale(1); }
  to {
    -webkit-transform: scale(0.4);
            transform: scale(0.4); } }

@-webkit-keyframes cursorAnim3 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  50% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0; } }

@keyframes cursorAnim3 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  50% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0; } }

.expand {
  -webkit-animation: cursorAnim3 .5s forwards;
          animation: cursorAnim3 .5s forwards;
  border: .2px solid red; }
  @media only screen and (max-width: 800px) {
    .expand {
      -webkit-animation: none;
              animation: none; } }

