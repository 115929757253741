@import './variables.scss';
@import './index.scss';

.LandingPage{
    top: 0;
    left: 0; 
    margin: 0;
    width: 100%;
    height: 100vh;
    background-color: #000;
    color: #fff; 
    position: absolute;
}

.sideways-nav-landing{ 
    transform: rotate(-90deg);
    height: 2rem;
    position: fixed;
    right: -11rem;
    background: #000;
    color: white;
    top: 60vh;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: 425px) {
        display: none;
    } 
    @media only screen and (min-width: 769px) {
        top: 60vh;  
    }
    @media only screen and (min-width: 1440px) {
        top: 60vh;
        right: -15rem;  
    } 
    .navigation-landing{
        list-style-type: none;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 24rem;
        @media only screen and (min-width: 1440px) {
            width: 33rem;
        }
        .navigation-link{
            color: white;
            li{
                font-weight: 1000;
                letter-spacing: 2px;
                font-size: 1rem;
                color: white;
                font-family: $headerFont;
                color: white;
                @media only screen and (min-width: 1440px) {
                    font-size: 1.3rem;
                }
                &:hover{
                    cursor: pointer;
                    color: #46e0b4;
                }
            } 
        }
    }
}

.LandingPage-title{
    padding-top: 266px;
    padding-left: 11%;
    color: white;
    @media only screen and (max-width: 425px) {
        margin: 0 auto;
        display: block;
        padding-left: 0rem;
     } 
    .presentation-name{
        font-size: 1.8rem;
        line-height: 1.21429em;
        letter-spacing: -.03571em;
        font-family: Helvetica,Arial,sans-serif;
        font-weight: 700;
        margin: 0;
        word-break: break-word;
        font-weight: 900;
        padding-bottom: 6px;
        color: white;
        @media only screen and (max-width: 425px) {
           padding-bottom: 0;
           font-size: 1rem;
        } 
        @media only screen and (min-width: 1920px) {
           //font-size: 7.8rem;
         } 
    }
    .presentation-landing-page{
        max-width: 31.27273rem;
        height: 4.63636rem;
        font-size: 5rem;
        font-weight: 800;
        color: white;
        padding-right: 13px;
        @media only screen and (max-width: 425px) {
            padding-right: 0;
            font-size: 3rem;
            height: 3.63636rem;
         }
    }
}


.landing-page-nav{
    color: white;
}


.scroll-down{ 
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 89%;
    left: 11%;
    color: white;
    @media only screen and (max-width: 425px) {
        padding-left: 0rem;
     } 
    .alternate {
        align-self: flex-end;
        @media only screen and (min-width: 1920px) {
            //font-size: 3rem;
        } 
    }
    
    .scroll-title{
        margin-bottom: 0;
        margin-top: 0;
        @media only screen and (min-width: 1025px) {
            font-size: 1.2rem;
        } 
    }
}

.typewriter {
	display: inline-block;
    .typewriter-text {
        display: inline-block;
        overflow: hidden;
        letter-spacing: 2px;
        animation: typing 2s steps(30, end), blink 1s step-end infinite;
        white-space: nowrap;
        font-weight: 700;
        border-right: 4px solid #fff;
        box-sizing: border-box;
        @media only screen and (min-width: 1920px) {
            overflow: visible;
            animation: none;
        } 
    }
}

//////////Description///////////////////////////////


.description-landing {
    width: 600px;
    font-family: $primary-font;
    color: white;
    font-size: 2rem;    
    padding-top: 13px;
    @media only screen and (max-width: 425px) {
        font-size: 1.5rem;  
        padding-top: 0;
    } 
    .words{
        display: inline;
        text-indent: 20px;
        span {
            @media only screen and (max-width: 425px) {
                width: 170px;  
            } 
        }
    }
}



/////////////////////ANIMATIONS///////////////////////////////


///////Rotating Words//////

$totalWords: 24;

@mixin animation($name, $time, $easing, $iteration-count, $start-delay ) {
  -webkit-animation: $name $time $easing $iteration-count $start-delay;
	-ms-animation: $name $time $easing $iteration-count $start-delay;
	animation: $name $time $easing $iteration-count $start-delay;
}

@mixin animation-delay($time) {
  -webkit-animation-delay: $time; 
	-ms-animation-delay: $time; 
	animation-delay: $time;   
}

.words span{
	position: absolute;
	opacity: 0;
	overflow: hidden;
	color: #ef3f4a;;
	@include animation(rotateWord, $totalWords * 3 + s, ease-in-out, infinite, 0s);
}

@for $i from 2 through $totalWords {
  .words span:nth-child(#{$i}) {
    @include animation-delay(3 * ($i - 1) + s); 
  }
}

@-webkit-keyframes rotateWord {
    0% { opacity: 0; }
    1% { opacity: 0; -webkit-transform: translateY(-30px); }
    2% { opacity: 1; -webkit-transform: translateY(0px); }
    4% { opacity: 1; -webkit-transform: translateY(0px); }
    5% { opacity: 0; -webkit-transform: translateY(30px); }
    80% { opacity: 0; }
    100% { opacity: 0; }
}
@keyframes rotateWord {
    0% { opacity: 0; }
    1% { opacity: 0; -webkit-transform: translateY(-30px); }
    2% { opacity: 1; -webkit-transform: translateY(0px); }
    4% { opacity: 1; -webkit-transform: translateY(0px); }
    5% { opacity: 0; -webkit-transform: translateY(30px); }
    80% { opacity: 0; }
    100% { opacity: 0; }
}
@-ms-keyframes rotateWord {
    0% { opacity: 0; }
    1% { opacity: 0; -webkit-transform: translateY(-30px); }
    2% { opacity: 1; -webkit-transform: translateY(0px); }
    4% { opacity: 1; -webkit-transform: translateY(0px); }
    5% { opacity: 0; -webkit-transform: translateY(30px); }
    80% { opacity: 0; }
    100% { opacity: 0; }
}


//Typewriter
@keyframes typing {
    from { 
        width: 0% 
    }
    to { 
        width: 100% 
    }
}

@keyframes blink {
    from, to { 
        border-color: transparent 
    }
    50% { 
        border-color: orange; 
    }
}

.animated .down{
    animation-duration: 1.3s;
    animation-delay: 2s;
    animation-iteration-count: infinite;
    @media only screen and (min-width: 1025px) {
        font-size: 1.2rem;
    } 
}

/////////////////////MEDIA QUERIES////////////////////////////

@media only screen and (max-width: 425px) {
    .presentation-name {
        width: 250px;
    }
    .typewriter {
        width: 250px;
    }
    .description-landing {
        width: 250px;
    }
    #landing-page-section {
        display: flex;
    }
    .words span {
        width: 50px;
    }
} 



@media only screen and (min-width: 1920px) {
    .down .icon {
        font-size: 3rem;
    }
 } 





  



