.main-container{
    margin-left: 10%;
}

.top-container{
    width: 100%;
    height: 40vh;
    @media only screen and (min-width: 1440px) {
        height: 26vh;
    }
    .description{
        margin-left: 56px;
        padding-top: 25px;
        @media only screen and (max-width: 425px) {
            margin-left: 0;
            padding-top: 0;
        }
    }
    .secondary-line{
        border: .5px solid black;
        display: flex;
        width: 30px;
    }
    p{
        width: 43%;
        margin-top: 3%;
    }
}

.bottom-container{
    height: 50vh;
    max-width: 1000px;
    padding: 0 1.5rem;
    margin: auto;
    @media only screen and (max-width: 425px) {
        padding: 0;
        height: 60vh;
    }
    .arrow{
        display: flex;
        flex-direction: column;
        cursor: pointer;
        @media only screen and (max-width: 425px) {
            z-index: 300;
            color: teal;
        } 
        i.icon.arrow.right{
            padding-left: inherit;
            padding-top: 5px;
        }

    } 
    .top-section-bottom-container{
        margin-left: 56px;
        @media only screen and (max-width: 425px) {
            margin-left: 0px;
        }  
    }
    h1{
        font-size: 92px;
        margin-top: -34px;
        z-index: 200;
        position: relative;
        color: black;
        @media only screen and (max-width: 425px) {
            color: sienna
        } 
    }
    .ui.primary.button, .ui.primary.buttons .button {
        background-color: #aef0d2;
        color: black;
        text-shadow: none;
        background-image: none;
        transition: all .2s ease-in .1s;
        margin-top: 48px;
        margin-left: 126px;
        width: 12rem;
        @media only screen and (max-width: 425px) {
            background-color: #aef0d2;
            color: black;
            text-shadow: none;
            background-image: none;
            transition: all .2s ease-in .1s;
            width: 12rem;
            margin-top: 2rem;
            margin-left: 0;
            display: block;
        }
        &:hover{
            background: #f9cbd1;
            color: #000;
        }
    }
    .ui.button{
        border-radius: none;
    }
}



.number{
    display: flex;
    align-items: center;
    flex-direction: row;
    align-content: space-between;
    .line{
        border: .5px solid black;
        width: 40px;
        justify-self: center;
    }
    h2{
        margin-top: 0px;
        padding-left: 15px;
    }
}

.carousel{
    padding: 0px 70px;
    margin-top: -113px;
    @media only screen and (max-width: 425px) {
        padding: 0;
    }
}

.carousel__container {
	overflow: hidden;
    height: 200px;
}
.carousel__list{
	position: relative;
	display: flex;
	flex-wrap: nowrap;
}
.carousel__list.unwrap{
	flex-wrap: nowrap;
}

.carousel__item{
	flex: 1 0 300px;
    height: 200px;
    width: 350px;
	background-color: #ccc;
	text-align: center;
    line-height: 100px;
}

.title-containter{
    padding: 0px 70px;
    width: 741;
    @media only screen and (max-width: 425px) {
        padding: 0;
    }
    .titles{
        @media only screen and (max-width: 425px) {
            margin-left: 0px;
        }
    }
}

.title-wrapper{
    overflow: hidden;
    width: 741;
}



.titles {
    display: flex;
    flex-direction: row;
    width: 741;
    margin-left: 57px;
    .general-title{
        flex: 1 0 400px;
        padding-top: 33px;
    }
    h4{
        margin-top: 0px;
        font-weight: 100;
    }
    h3{
        font-weight: 10360;
    }
    .header-description {
        a {
            color: black;
            transition: all .12s ease-in-out;
            font-weight: 900;
            &:hover {
                color: #46e0b4;
            }
        }
    }
}

.imageView{ 
    width: 300px;
    height: 250px;
}


#arrow-left-l {
    color: black;
    &:hover{
        color: #EB4E7A;
    }
    @media only screen and (max-width: 425px) {
        z-index: 300;
        color: #000000;
        font-size: 2rem;
    } 
}

#arrow-right-r {
    color: black;
    &:hover{
        color: #EB4E7A;
    }
    @media only screen and (max-width: 425px) {
        z-index: 300;
        color: #000000;
        font-size: 2rem;
    } 
}

.carousel__list {
    animation-delay: 6s;
}

////////////////////////CAROUSELOVERLAY/////////////////////////////

.project-section{
    display: grid;
    grid-template-columns: 1fr 1fr;
    .project-box{
        position: relative;
        padding: 30px;
        transition: all .3s ease-in-out;
        background-size: contain;
        width: 400px;
        height: 350px;
        .grey-bg{
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            background: #efefef;
            transition: all .3s ease-in-out;
            opacity: 0;
            overflow: hidden;
            .project-info{
                position: absolute;
                padding: 18px;
                padding-left: 45px;
                top: 7rem;
                width: 100%;
                @media only screen and (max-width: 425px) {
                    padding-left: 0;
                }
                .project-details{
                    position: relative;
                    font-size: 1rem;
                    text-transform: capitalize;
                    margin: 0 0 1rem;
                    transform: translate3d(-400px, 0, 0);
                    transition: all .3s ease-in;
                }
                ////animates after 2s
                .project-title{
                    font-size: 1.3rem;
                    text-transform: uppercase;
                    font-weight: 800;
                    transition: all .3s ease-in .1s;
                    transform: translate3d(-100px, 0, 0);
                    opacity: 0;
                    font-weight: 900;
                }
                .long {
                    position: absolute;
                    bottom: 15px;
                    right: 13px;
                    font-size: 2rem;
                    color: #000;
                    transform: translate3d(-30px, 0, 0);
                    transition: all .1s ease-in-out;
                    @media only screen and (max-width: 425px) {
                        right: 156px;
                    }
                }
            }
            &:hover{
                opacity: .9;
                .project-info{
                    .project-details {
                        transform: translate3d(0, 0, 0);
                        opacity: 1;
                    }
                    .project-title {
                        transform: translate3d(0, 0, 0);
                        opacity: 1;
                    }
                    .long {
                        transform: translate3d(0, 0, 0);
                    }

                }
            }
        }
    }
}
