@import './variables.scss';


.navigation {
    transform: translateX(-100vw);
    transition: transform .8s cubic-bezier(0.86, 0, 0.07, 1);
    top: 0;
    left: 0;
    right: 0; 
    left: 0;
    position: fixed;
    z-index: 10000000;
    @media only screen and (min-width: 700px) {
        display: none;
    } 
    &__background {
        height: 100vh;
        width: 100vw;
        background: #c9af98;
    }

    &__nav {
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1500;
        opacity: 1;
        width: 100%;
        transition: all .8s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    }

    &__list {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        list-style: none;
        text-align: center;
        width: 100%;
    }
    &__item {
        margin: 1rem;
    }

    &__link {
        &:link,
        &:visited {
            display: inline-block;
            font-size: 2rem;
            font-weight: 300;
            padding: 1rem 2rem;
            color: #3a4660;
            text-decoration: none;
            text-transform: uppercase;
            background-image: linear-gradient(120deg, transparent 0%, transparent 50%, white 50%);
            background-size: 220%;
            transition: all .4s;

            span {
                margin-right: 1.5rem;
                display: inline-block;
            }
        }
        
        &:hover,
        &:active {
            background-position: 100%;
            color: $tertiary-color;
            transform: translateX(1rem);
        }
    }
}

#active-menu {
    transform: translateX(0vw);
    display: block;
}