@import './variables.scss';


.items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.item {
  position: relative;
  background: #efefef;
  box-shadow: 0.3rem 0.4rem 0.4rem rgba(0, 0, 0, 0.4);
  overflow: hidden;
  &::after {
    content: '';
    position: absolute;
    display: block;
    background: inherit;
    opacity: 0.9;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: scale(2) translateX(-75%) translateY(-75%) rotate(-28deg);
    transition: transform 3s cubic-bezier(0.2, 1, 0.3, 1);
  }
 

  // Bring in main color overlay
  &:hover:after {
    transform: scale(2) translateX(0) translateY(0) rotate(-28deg);
  }

  // Scale image on hover
  &:hover &-image {
    transform: scale(1.2);
  }

  // Bring in text on hover
  &:hover &-text {
    opacity: 1;
    transform: translateY(0);
  }

  &-image {
    height: auto;
    transform: translateZ(0);
    display: block;
    transition: transform 750ms cubic-bezier(0.2, 1, 0.3, 1);
  }

  &-image:before {
    content: '';
    display: block;
    padding-top: 75%;
    overflow: hidden;
  }

  &-image img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    line-height: 0;
  }

  &-text {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    text-align: center;
    z-index: 1;
    color: #000;
    transform: translateY(-20%);
    transition: opacity 500ms cubic-bezier(0.2, 1, 0.3, 1),
    transform 500ms cubic-bezier(0.2, 1, 0.3, 1);
    transition-delay: 300ms;
  }

  &-text-wrap {
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
  }
 
  &-text-title {
    font-size: 2rem;
    padding: 0 1rem;
    margin: 5px 0 0 0;
  }

  &-text-category { 
    text-transform: uppercase; 
    font-size: 1.2rem;
    opacity: 0.7; 
    margin: 0;
  }
}
.main-container {
  @media only screen and (max-width: 1280px) {
    max-width: $website-width;
    padding: 0 1.5rem;
    margin: auto;
    overflow: hidden;
  }
  @media only screen and (min-width: 1280px) {
    max-width: 1100px;
    padding: 0 1.5rem;
    margin: auto;
    overflow: hidden;
  }
}

// Buttons
%btn-shared {
  display: inline-block;
  padding: 0.8rem 2rem;
  transition: all 0.5s;
  border: none;
  cursor: pointer;
}

.btn {
  &-main {
    @extend %btn-shared;
    color: #333;
    background-color: $main-color;
  }

  &-light {
    @extend %btn-shared;
    color: #333;
    background-color: $light-color;
  }

  &-dark {
    @extend %btn-shared;
    color: #f4f4f4;
    background-color: $dark-color;
  }
}


// Backgrounds
.bg {
  &-main {
    background: $main-color;
    color: set-text-color($main-color);
  }
  &-dark {
    background: $dark-color;
    color: set-text-color($dark-color);
  }
  &-light {
    background: $light-color;
    color: set-text-color($light-color);
  }
  &-medium {
    background: $medium-color;
    color: set-text-color($medium-color);
  }
}

.lead {
  font-size: 1.3rem;
  margin-bottom: 2rem;
}

.text-center {
  text-align: center;
}

// Padding
.py {
  padding-top: 5rem;
  &-1 {
    padding: 1rem 0;
  }
  &-2 {
    padding: 2rem 0;
  }
  &-3 {
    padding: 3rem 0;
  }
  &-4 {
    padding: 4rem 0;
  }
}

// Margin
.my {
  &-1 {
    margin: 1rem 0;
  }
  &-2 {
    margin: 2rem 0;
  }
  &-3 {
    margin: 3rem 0;
  }
  &-4 {
    margin: 4rem 0;
  }
}

.main-container-menu{
  display: flex;
  justify-content: space-between;
  .section-title {
    letter-spacing: 2px;
    font-size: 2rem;
    display: block;
    text-align: start;
    font-weight: 900;
    text-transform: uppercase;
    font-family: $primary-font;
  }
  .menu{
    display: flex;
    .menu-item {
      padding-left: 20px;
      letter-spacing: 2px;
    }
  }
}

.section-subtitle-{
  display: block;
  text-align: start;
  padding-bottom: 3rem;
  color: #e0bd44;
}

.bottom-line {
  height: .5px;
  width: 100%;
  background: #bbb;
  display: block;
  margin: 0 auto 1rem auto;
}
